
import { defineComponent } from "vue";
import SignUpRegistration from "@/components/SignUpRegistration.vue";
import AdvancedGaugeChartHome from "@/components/charts/AdvancedGaugeChartHome.vue";
import { CurrentWeatherModel } from "@/models/weather/Weather";

export default defineComponent({
  data: () => ({
    sensor: null,
    weather: null as CurrentWeatherModel | null,
  }),
  mounted() {
    this.loadAirQualitySensor();
    this.loadWeather();
  },
  methods: {
    loadAirQualitySensor() {
      let cityId = window.VUE_APP_CITY_ID || process.env.VUE_APP_CITY_ID;
      this.$http
        .get("api/v2/air-quality?perPage=1&filters[city_id]=" + cityId)
        .then((response) => {
          if (response.data.data.length === 0) {
            return;
          }
          this.sensor = response.data.data[0];
        });
    },
    loadWeather() {
      let cityId = window.VUE_APP_CITY_ID || process.env.VUE_APP_CITY_ID;
      this.$http
        .get("api/v1/weather/" + cityId + "?current=true")
        .then((response) => {
          this.weather = new CurrentWeatherModel(response.data);
        });
    },
  },
  components: { AdvancedGaugeChartHome, SignUpRegistration },
});
