import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex homeParking" }
const _hoisted_2 = { class: "showMap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MapCustom = _resolveComponent("MapCustom")!
  const _component_Tree = _resolveComponent("Tree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "showTree",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCard && _ctx.toggleCard(...args)))
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MapCustom, {
        markers: _ctx.markers,
        center: _ctx.center,
        onCenterChanged: _ctx.updateCenter,
        onZoomChanged: _ctx.updateZoom
      }, null, 8, ["markers", "center", "onCenterChanged", "onZoomChanged"])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["mapTree", { closed: _ctx.cardOpen }])
    }, [
      _createVNode(_component_Tree, {
        value: _ctx.nodes,
        "selection-keys": _ctx.selectedNodes,
        "onUpdate:selection-keys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedNodes) = $event)),
        selectionMode: "checkbox",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fillMarkers()))
      }, null, 8, ["value", "selection-keys"])
    ], 2)
  ]))
}