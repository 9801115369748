const requireModule = require.context(".", false, /\.json$/);

// eslint-disable-next-line
const messages: any = {};

requireModule.keys().forEach((filename) => {
  const languageName = filename.replace(/(\.\/|\.json)/g, "");
  messages[languageName] = requireModule(filename);
});

export default messages;
