
import { defineComponent } from "vue";
import { Coordinates, Marker } from "@/typings/Marker";

export default defineComponent({
  data: () => ({
    temporaryCenter: { lat: 45.91831, lng: 14.229618 } as Coordinates,
    zoom: 15,
    hoveredMarker: null as Marker | null,
  }),
  props: {
    center: {
      type: Object as () => Coordinates,
      default: () => ({ lat: 45.91831, lng: 14.229618 }),
    },
    markers: {
      type: Array as () => Marker[],
      default: () => {
        return [];
      },
    },
  },
  mounted() {
    if (this.markers !== null && this.markers.length === 1) {
      this.$emit("centerChanged", this.markers[0].position);
    }
  },
  methods: {
    markerIcon(marker: Marker) {
      if (marker.type === "parking") {
        return "/marker3.svg";
      } else if (marker.type === "air_quality") {
        return "/marker2.svg";
      }

      return "/marker.svg";
    },
    showInfo(marker: Marker) {
      console.log("hovering", marker);
    },
    openInfo(marker: Marker) {
      if (marker.on_click_route_name === null) {
        return;
      }
      this.$router.push({
        name: marker.on_click_route_name,
        params: { id: marker.id },
      });
      this.$emit("close");
    },
    updateTemporaryCenter(event: { lat(): number; lng(): number }) {
      // This event is called every time center coordinates changes.
      // If we update center property directly, map freezes.
      // Thats why we only update center when dragend event fires
      this.temporaryCenter = { lat: event.lat(), lng: event.lng() };
    },
    updateCenter() {
      this.$emit("centerChanged", this.temporaryCenter);
    },
    updateZoom(value: number) {
      // Zoom event is called twice for some reason
      if (this.zoom === value) {
        return;
      }
      this.zoom = value;
      this.$emit("zoomChanged", value);
    },
  },
});
