import { ActionContext } from "vuex";

interface State {
  consents: Consents | null;
}

interface Consents {
  system: boolean;
  analytics: boolean;
  social: boolean;
}

function getConsents(): Consents | null {
  const localStorageConsents = localStorage.getItem("consents");
  return localStorageConsents ? JSON.parse(localStorageConsents) : null;
}

const state = (): State => ({
  consents: getConsents(),
});

const getters = {
  consents: (state: State): Consents | null => {
    return state.consents;
  },
};

const mutations = {
  setConsents(state: State, consents: Consents): void {
    state.consents = consents;
  },
};

const actions = {
  setConsents(context: ActionContext<State, State>, consents: Consents): void {
    localStorage.setItem("consents", JSON.stringify(consents));
    context.commit("setConsents", consents);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
