declare const window: Window & {
  VUE_DEFAULT_LOCALE: string;
};

import { createI18n } from "vue-i18n";

import messages from "@/localization";

export const defaultLocale = window.VUE_DEFAULT_LOCALE || "en";

export const availableLocales = Object.keys(messages);

const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: "en",
  messages,
});

export default i18n;
