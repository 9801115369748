
import Tree from "primevue/tree";
import { defineComponent } from "vue";
import { Coordinates, Marker } from "@/typings/Marker";
import { QueryParameters } from "@/typings/QueryParameters";
import { Parking } from "@/typings/Parking";
import { AirQualitySensor } from "@/typings/AirQualitySensor";
import MapCustom from "@/components/MapCustom.vue";

export default defineComponent({
  data: () => ({
    center: { lat: 45.91831, lng: 14.229618 } as Coordinates | null,
    zoom: 11,
    parkingsArray: [] as Marker[],
    airQualitySensorsArray: [] as Marker[],
    selectedNodes: [],
    cardOpen: false,
  }),
  methods: {
    toggleCard() {
      this.cardOpen = !this.cardOpen;
    },
    updateCenter(value: Coordinates) {
      this.center = value;
      this.fillMarkers();
    },
    updateZoom(value: number) {
      this.zoom = value;
      this.fillMarkers();
    },
    fillMarkers() {
      let params: QueryParameters & {
        latitude?: number;
        longitude?: number;
        radius?: number;
      } = {
        filters: {
          city_id: window.VUE_APP_CITY_ID || process.env.VUE_APP_CITY_ID,
        },
      };
      if (this.center !== null) {
        params.latitude = this.center.lat;
        params.longitude = this.center.lng;
        params.radius = Math.pow(2, 27 - this.zoom);
      }

      if (this.selectedNodes[this.nodes[0].key]) {
        this.getParkings(params);
      }
      if (this.selectedNodes[this.nodes[1].key]) {
        this.getAirQualitySensors(params);
      }
    },
    getParkings(params: QueryParameters) {
      this.$http.get("api/v2/parkings", { params }).then((response) => {
        let parkings = response.data.data as Parking[];
        this.parkingsArray = parkings.map((parking) => ({
          id: parking.id,
          position: {
            lat: parseFloat(parking.latitude),
            lng: parseFloat(parking.longitude),
          },
          on_click_route_name: "ParkingById",
          type: "parking",
        }));
      });
    },
    getAirQualitySensors(params: QueryParameters) {
      this.$http.get("api/v2/air-quality", { params }).then((response) => {
        let sensors = response.data.data as AirQualitySensor[];
        this.airQualitySensorsArray = sensors.map((sensor) => ({
          id: sensor.id,
          position: {
            lat: parseFloat(sensor.latitude),
            lng: parseFloat(sensor.longitude),
          },
          on_click_route_name: "AirQualityById",
          type: "air_quality",
        }));
      });
    },
  },
  computed: {
    markers() {
      let markers = [] as Marker[];

      if (this.selectedNodes[this.nodes[0].key]) {
        markers = markers.concat(this.parkingsArray);
      }

      if (this.selectedNodes[this.nodes[1].key]) {
        markers = markers.concat(this.airQualitySensorsArray);
      }
      return markers;
    },
    nodes() {
      return [
        {
          key: 0,
          label: this.$t("map.parkings"),
          icon: "warpit_icon_folder_open",
          //children: [
          //  { key: "0 - 1", icon: "warpit_icon_parking", label: "Center" },
          //  { key: "0 - 2", icon: "warpit_icon_parking", label: "Bežigrad" },
          //  { key: "0 - 3", icon: "warpit_icon_parking", label: "Center" },
          //  { key: "0 - 4", icon: "warpit_icon_parking", label: "Moste Polje" },
          //  { key: "0 - 5", icon: "warpit_icon_parking", label: "Vič" },
          //  { key: "0 - 6", icon: "warpit_icon_parking", label: "Rudnik" },
          //  { key: "0 - 7", icon: "warpit_icon_parking", label: "Šiška" },
          //],
        },
        //{
        //  key: 1,
        //  label: "Trafic Events",
        //  icon: "warpit_icon_car",
        //  children: [{ key: "1 - 1", icon: "warpit_icon_car", label: "Child" }],
        //},
        //{
        //  key: 2,
        //  label: "Buses",
        //  icon: "warpit_icon_bus",
        //  children: [
        //    { key: "2 - 1", icon: "warpit_icon_bus", label: "Linija 1" },
        //    { key: "2 - 2", icon: "warpit_icon_bus", label: "Linija 2" },
        //    { key: "2 - 3", icon: "warpit_icon_bus", label: "Linija 3" },
        //    { key: "2 - 4", icon: "warpit_icon_bus", label: "Linija 4" },
        //  ],
        //},
        //{
        //  key: 3,
        //  label: "Car Sharing",
        //  icon: "warpit_icon_bike-sharing",
        //  children: [
        //    { key: "3 - 1", icon: "warpit_icon_car-sharing", label: "Car" },
        //  ],
        //},
        //{
        //  key: 4,
        //  label: "Bicycle Sharing",
        //  icon: "warpit_icon_bike-sharing",
        //  children: [
        //    { key: "4 - 1", icon: "warpit_icon_car-sharing", label: "Car" },
        //  ],
        //},
        {
          key: 5,
          label: this.$t("map.aqi"),
          icon: "warpit_icon_air-quality",
          //children: [
          //  { key: "5 - 1", icon: "warpit_icon_car-sharing", label: "Car" },
          //],
        },
      ];
    },
  },
  components: { MapCustom, Tree },
});
