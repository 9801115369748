
import { defineComponent } from "vue";
import MapCustom from "@/components/MapCustom.vue";
import SidebarWrapper from "@/components/SidebarWrapper.vue";
import SidebarStations from "@/components/publicTransport/SidebarStations.vue";
import { Stop } from "@/typings/Stop";
import { Marker } from "@/typings/Marker";
import MapStations from "@/components/publicTransport/MapStations.vue";

export default defineComponent({
  name: "Stations",
  components: { SidebarWrapper, MapCustom },
  data: () => ({
    sidebarComponent: SidebarStations,
    station: null as Stop | null,
    mapComponent: MapStations,
  }),
  mounted() {
    this.loadStation();
  },
  methods: {
    loadStation() {
      if (
        Object.prototype.hasOwnProperty.call(this.$route.params, "id") === false
      ) {
        return;
      }
      this.$http
        .get("api/v1/public-transport/1/stops/" + this.$route.params.id)
        .then((response) => {
          this.station = response.data;
        });
    },
  },
  computed: {
    marker(): Marker | null {
      if (
        this.station === null ||
        this.station.latitude === null ||
        this.station.longitude === null
      ) {
        return null;
      }
      return {
        id: this.station.id,
        position: {
          lat: parseFloat(this.station.latitude),
          lng: parseFloat(this.station.longitude),
        },
        on_click_route_name: null,
        type: "station",
      };
    },
  },
  watch: {
    "$route.params.id"() {
      this.loadStation();
    },
  },
});
