
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "SidebarEvents",
  components: {},

  setup() {
    const newsArchives = ref([
      {
        name: "Vrsta dogodka",
        items: [
          { name: "December", to: "/" },
          { name: "November", to: "/" },
          { name: "Oktober", to: "/" },
          { name: "September", to: "/" },
          { name: "Avgust", to: "/" },
          { name: "Julij", to: "/" },
          { name: "Junij", to: "/" },
          { name: "Maj", to: "/" },
          { name: "April", to: "/" },
          { name: "Marec", to: "/" },
          { name: "Februar", to: "/" },
          { name: "Januar", to: "/" },
        ],
      },
      {
        name: "Organizator",
        items: [
          { name: "December", to: "/" },
          { name: "November", to: "/" },
          { name: "Oktober", to: "/" },
          { name: "September", to: "/" },
          { name: "Avgust", to: "/" },
          { name: "Julij", to: "/" },
          { name: "Junij", to: "/" },
          { name: "Maj", to: "/" },
          { name: "April", to: "/" },
          { name: "Marec", to: "/" },
          { name: "Februar", to: "/" },
          { name: "Januar", to: "/" },
        ],
      },
    ]);

    return {
      newsArchives,
    };
  },
});
