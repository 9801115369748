
import AccordionTab from "primevue/accordiontab";
import Accordion from "primevue/accordion";
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    pushRoute(name: string) {
      this.$router.push({ name });
      this.$emit("close");
    },
  },
  components: { Accordion, AccordionTab },
});
