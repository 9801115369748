import {
  Weather as WeatherInterface,
  HourlyWeather as HourlyWeatherInterface,
  CurrentWeather as CurrentWeatherInterface,
} from "@/typings/Weather";
import moment from "moment";

export class WeatherModel implements WeatherInterface {
  timeString: string;
  time: number;
  temperatureHigh: number;
  temperatureLow: number;
  sunriseTime: number;
  sunsetTime: number;
  icon: string;
  hourly: HourlyWeatherModel[] | undefined;
  constructor(json: WeatherInterface) {
    this.time = json.time;
    this.timeString = moment.unix(this.time).format("DD.MM.yyyy");
    this.temperatureHigh = Math.round(json.temperatureHigh);
    this.temperatureLow = Math.round(json.temperatureLow);
    this.sunriseTime = json.sunriseTime;
    this.sunsetTime = json.sunsetTime;
    this.icon = mapIcons(json.icon);
  }

  filterHourly(hourly: HourlyWeatherInterface[]): void {
    const halfDay = 43200;
    const startTime = this.time - halfDay;
    const endTime = this.time + halfDay;
    this.hourly = hourly
      .filter((hourlyData: HourlyWeatherInterface) => {
        return hourlyData.time > startTime && hourlyData.time < endTime;
      })
      .map((hourlyData: HourlyWeatherInterface) => {
        return new HourlyWeatherModel(
          hourlyData.time,
          hourlyData.temperature,
          hourlyData.icon
        );
      });
  }
}

export class CurrentWeatherModel implements CurrentWeatherInterface {
  timeString: string;
  time: number;
  temperature: number;
  humidity: number;
  pressure: number;
  windSpeed: number;
  windBearing: number;
  windDirection: string;
  icon: string;
  constructor(json: CurrentWeatherInterface) {
    this.time = json.time;
    this.timeString = moment.unix(json.time).format("DD.MM.yyyy HH:mm");
    this.temperature = Math.round(json.temperature);
    this.humidity = json.humidity;
    this.pressure = json.pressure;
    this.windSpeed = Math.round(json.windSpeed);
    this.windBearing = json.windBearing;
    this.windDirection = this.setWindDirection();
    this.icon = mapIcons(json.icon);
  }

  setWindDirection(): string {
    const divided = this.windBearing / 45;
    let direction;
    if (divided >= 7.5 && divided < 8) {
      direction = 0;
    } else {
      direction = Math.round(divided);
    }
    return ["N", "NE", "E", "SE", "S", "SW", "W", "NW"][direction];
  }
}

class HourlyWeatherModel implements HourlyWeatherInterface {
  time: number;
  timeString: string;
  temperature: number;
  icon: string;
  constructor(time: number, temperature: number, icon: string) {
    this.time = time;
    this.timeString = moment.unix(time).format("HH:mm");
    this.temperature = Math.round(temperature);
    this.icon = mapIcons(icon);
  }
}

function mapIcons(iconString: string): string {
  let icon;
  switch (iconString) {
    case "clear-day":
      icon = "clear-sky";
      break;
    case "clear-night":
      icon = "clear-night";
      break;
    case "partly-cloudy-day":
      icon = "partly-cloudy-day";
      break;
    case "partly-cloudy-night":
      icon = "partly-cloudy-night";
      break;
    case "cloudy":
      icon = "cloudy";
      break;
    case "rain":
      icon = "rain";
      break;
    case "snow":
      icon = "snow";
      break;
    case "wind":
      icon = "wind";
      break;
    case "fog":
      icon = "fog";
      break;
    //sleet
    default:
      icon = "clear-sky";
  }
  return icon;
}
