import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GMapInfoWindow = _resolveComponent("GMapInfoWindow")!
  const _component_GMapMarker = _resolveComponent("GMapMarker")!
  const _component_GMapMap = _resolveComponent("GMapMap")!

  return (_openBlock(), _createBlock(_component_GMapMap, {
    options: {
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
      clickableIcons: false,
    },
    center: _ctx.center,
    zoom: _ctx.zoom,
    onZoom_changed: _ctx.updateZoom,
    onCenter_changed: _ctx.updateTemporaryCenter,
    onDragend: _ctx.updateCenter
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.markers, (marker) => {
        return (_openBlock(), _createBlock(_component_GMapMarker, {
          key: marker.id,
          position: marker.position,
          clickable: true,
          draggable: false,
          icon: _ctx.markerIcon(marker),
          onMouseover: ($event: any) => (_ctx.hoveredMarker = marker),
          onMouseout: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hoveredMarker = null)),
          onClick: ($event: any) => (_ctx.openInfo(marker))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_GMapInfoWindow, {
              opened: _ctx.hoveredMarker && _ctx.hoveredMarker.id === marker.id,
              onMouseleave: ($event: any) => (_ctx.onMouseLeave(marker))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("strong", null, _toDisplayString(marker.type), 1)
                ])
              ]),
              _: 2
            }, 1032, ["opened", "onMouseleave"])
          ]),
          _: 2
        }, 1032, ["position", "icon", "onMouseover", "onClick"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["center", "zoom", "onZoom_changed", "onCenter_changed", "onDragend"]))
}