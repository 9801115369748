
import { defineComponent } from "vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import SidebarWrapper from "@/components/SidebarWrapper.vue";
import SidebarParking from "@/components/parking/SidebarParking.vue";
import MapParking from "@/components/parking/MapParking.vue";
import BasicDonutChart from "@/components/charts/BasicDonutChart.vue";
import { Parking } from "@/typings/Parking";
import { getOccupancy, getValueColorClass } from "@/services/Parking";
import { RRule } from "rrule";
import MapCustom from "@/components/MapCustom.vue";
import { Marker } from "@/typings/Marker";

export default defineComponent({
  data: () => ({
    sidebarComponent: SidebarParking,
    mapComponent: MapParking,
    parking: null as Parking | null,
    occupancy: 0,
  }),
  mounted() {
    this.loadParkings();
  },
  methods: {
    loadParkings() {
      if (
        Object.prototype.hasOwnProperty.call(this.$route.params, "id") === false
      ) {
        return;
      }
      this.$http
        .get("api/v2/parkings/" + this.$route.params.id)
        .then((response) => {
          this.parking = response.data as Parking;
          this.occupancy = getOccupancy(this.parking);
        });
    },
    getRRuleText(recurrence: string) {
      let rrule = RRule.fromString(recurrence);
      return rrule.toText();
    },
    getValueColorClass(occupied: number, spaces: number): string {
      return getValueColorClass(occupied, spaces);
    },
  },
  watch: {
    "$route.params.id"() {
      this.loadParkings();
    },
  },
  computed: {
    marker(): Marker | null {
      if (
        this.parking === null ||
        this.parking.latitude === null ||
        this.parking.longitude === null
      ) {
        return null;
      }
      return {
        id: this.parking.id,
        position: {
          lat: parseFloat(this.parking.latitude),
          lng: parseFloat(this.parking.longitude),
        },
        on_click_route_name: null,
        type: "parking",
      };
    },
  },
  components: {
    BasicDonutChart,
    Accordion,
    AccordionTab,
    SidebarWrapper,
    MapCustom,
  },
});
