
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  components: {},
  created() {
    const locale = localStorage.getItem("locale");
    if (locale !== null) {
      this.$i18n.locale = locale;
      this.$moment.locale(locale);
    }
  },
});
