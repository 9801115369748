<template>
  <div class="responsiveShow">
    <img
      alt="Super mesto Logatec"
      src="@/assets/images/responsiveCrestTxt.svg"
    />
    <hr />
  </div>
  <div class="mainImage"></div>
</template>

<script>
export default {
  name: "MainImage",
};
</script>

<style scoped></style>
