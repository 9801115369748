
import Search from "@/components/Search.vue";
import { QueryParameters } from "@/typings/QueryParameters";
import { defineComponent } from "vue";
import { Stop } from "@/typings/Stop";
import InfinityScroll from "@/components/InfinityScroll.vue";
import { Paginated } from "@/typings/Paginated";

export default defineComponent({
  components: { InfinityScroll, Search },
  data: () => ({
    stops: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<Stop>,
    searchTerm: "",
  }),
  mounted() {
    this.loadStops();
  },
  methods: {
    loadStops(page = 1) {
      let cityId = window.VUE_APP_CITY_ID || process.env.VUE_APP_CITY_ID;

      let url = "api/v1/public-transport/" + cityId + "/stops";
      let params: QueryParameters = {
        orderBy: {
          name: "ASC",
        },
        page,
      };
      if (this.searchTerm) {
        params.filters = {
          name: "%" + this.searchTerm + "%",
        };
      }
      this.$http.get(url, { params }).then((response) => {
        if (page !== 1) {
          this.stops.data = this.stops.data.concat(response.data.data);
          this.stops.current_page = response.data.current_page;
          return;
        }
        this.stops = response.data;

        if (
          Object.prototype.hasOwnProperty.call(this.$route.params, "id") ===
            true ||
          this.stops.data.length === 0 ||
          this.$route.name !== "Station"
        ) {
          return;
        }

        let firstStop: { id: number } = this.stops.data[0];
        this.$router.push({
          name: "StationById",
          params: { id: firstStop.id },
        });
      });
    },
  },
});
