
import { defineComponent } from "vue";

import Dialog from "primevue/dialog";
import MapCustom from "@/components/MapCustom.vue";
import { Coordinates, Marker } from "@/typings/Marker";
import { QueryParameters } from "@/typings/QueryParameters";
import { AirQualitySensor } from "@/typings/AirQualitySensor";

export default defineComponent({
  data: () => ({
    showMap: false,
    center: { lat: 45.91831, lng: 14.229618 } as Coordinates,
    zoom: 15,
    markers: [] as Marker[],
  }),
  methods: {
    openMap() {
      this.loadAirQualitySensor();
      this.showMap = true;
    },
    closeMap() {
      this.showMap = false;
    },
    updateCenter(value: Coordinates) {
      this.center = value;
      this.loadAirQualitySensor();
    },
    updateZoom(value: number) {
      this.zoom = value;
      this.loadAirQualitySensor();
    },
    loadAirQualitySensor() {
      let cityId = window.VUE_APP_CITY_ID || process.env.VUE_APP_CITY_ID;
      let params: QueryParameters & {
        latitude?: number;
        longitude?: number;
        radius?: number;
      } = {
        filters: {
          city_id: cityId,
        },
        latitude: this.center.lat,
        longitude: this.center.lng,
        radius: Math.pow(2, 27 - this.zoom),
      };

      this.$http.get("api/v2/air-quality", { params }).then((response) => {
        let airQualitySensors = response.data.data as AirQualitySensor[];
        this.markers = airQualitySensors.map((airQualitySensor) => ({
          id: airQualitySensor.id,
          position: {
            lat: parseFloat(airQualitySensor.latitude),
            lng: parseFloat(airQualitySensor.longitude),
          },
          on_click_route_name: "AirQualityById",
          type: "air_quality",
        }));
      });
    },
  },
  components: { MapCustom, Dialog },
});
