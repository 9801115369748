import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventSlider = _resolveComponent("EventSlider")!
  const _component_Map = _resolveComponent("Map")!
  const _component_NewsSlider = _resolveComponent("NewsSlider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_EventSlider),
    _createVNode(_component_Map),
    _createVNode(_component_NewsSlider)
  ], 64))
}