import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventCard = _resolveComponent("EventCard")!
  const _component_SidebarWrapper = _resolveComponent("SidebarWrapper")!

  return (_openBlock(), _createBlock(_component_SidebarWrapper, {
    title: _ctx.$t('events.title'),
    sidebarComponent: _ctx.sidebarComponent,
    mapComponent: _ctx.mapComponent
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.events.data, (event) => {
        return (_openBlock(), _createBlock(_component_EventCard, {
          event: event,
          key: event.id
        }, null, 8, ["event"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["title", "sidebarComponent", "mapComponent"]))
}