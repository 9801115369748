<template>
  <div class="wp1200 withPadding">
    <h2 class="mainTitle pages">{{ $t("about_us.title") }}</h2>
    <div>
      <p>{{ $t("about_us.hello") }}</p>
      <p>
        {{ $t("about_us.we_offer") }}
      </p>
      <p>
        {{ $t("about_us.users_can") }}
      </p>
      <p>
        {{ $t("about_us.available") }}
      </p>
      <p>
        {{ $t("about_us.we_want") }}
      </p>
      <p>{{ $t("about_us.contact") }}</p>
      <p>
        Občina Logatec,<br />
        Tržaška cesta 50 a,<br />
        1370 Logatec
      </p>
      <p>
        Tel.: (01) 7590600<br />
        Fax.: (01) 7590620<br />
        Email: obcina.logatec@logatec.si
      </p>
      <p>
        Občina Logatec<br />
        Župan: Berto Menard
      </p>
      <p>
        <router-link :to="{ name: 'TermsAndConditions' }">
          {{ $t("about_us.general_terms") }}</router-link
        ><br />
        <router-link :to="{ name: 'PrivacyPolicy' }">{{
          $t("about_us.privacy")
        }}</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>

<style scoped></style>
