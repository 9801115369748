<template>
  <router-link
    :to="{ name: 'Article', params: { id: article.id } }"
    class="boxMessage noPad"
  >
    <div class="eventCard news">
      <div class="content">
        <i class="warpit_icon_news"></i>
        <h2>{{ article.title }}</h2>
        <p>
          {{ article.summary }}
        </p>
      </div>
      <img v-if="article.image_url !== null" :src="article.image_url" />
      <span class="when">{{
        article.datetime !== null
          ? $moment(article.datetime).fromNow()
          : $moment(article.created_at).fromNow()
      }}</span>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    article: Object,
  },
};
</script>

<style scoped></style>
