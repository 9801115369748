
import { defineComponent } from "vue";
import EventCard from "@/components/events/EventCard.vue";
import SidebarWrapper from "@/components/SidebarWrapper.vue";
import SidebarEvents from "@/components/events/SidebarEvents.vue";
import MapEvents from "@/components/events/MapEvents.vue";

import { QueryParameters } from "@/typings/QueryParameters";
import { Event } from "@/typings/Event";

export default defineComponent({
  data: () => ({
    sidebarComponent: SidebarEvents,
    mapComponent: MapEvents,
    events: {
      data: [] as Event[],
    },
  }),
  mounted() {
    this.loadEvents();
  },
  methods: {
    loadEvents() {
      let params: QueryParameters & {
        category_ids?: [number];
        datetime?: [string, string];
      } = {
        orderBy: { datetime: "ASC" },
      };
      if (this.selectedCategoryId !== null) {
        params.category_ids = [this.selectedCategoryId];
      }
      if (this.from !== null && this.until !== null) {
        params.datetime = [this.from, this.until];
      }
      this.$http.get("api/v1/events", { params }).then((response) => {
        this.events = response.data;
      });
    },
  },
  computed: {
    selectedCategoryId() {
      return Object.prototype.hasOwnProperty.call(
        this.$route.query,
        "category_id"
      ) && this.$route.query.category_id !== null
        ? parseInt(this.$route.query.category_id.toString())
        : null;
    },
    from() {
      return Object.prototype.hasOwnProperty.call(this.$route.query, "from") &&
        this.$route.query.from !== null
        ? this.$moment(this.$route.query.from).toISOString()
        : this.$moment().toISOString();
    },
    until() {
      return Object.prototype.hasOwnProperty.call(this.$route.query, "until") &&
        this.$route.query.until !== null
        ? this.$moment(this.$route.query.until).toISOString()
        : this.$moment().add(1, "months").toISOString();
    },
  },
  watch: {
    "$route.query": {
      handler() {
        this.loadEvents();
      },
      deep: true,
    },
  },
  components: {
    EventCard,
    SidebarWrapper,
  },
});
