
import { defineComponent } from "vue";

import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Pagination } from "swiper";

import "swiper/swiper-bundle.css";

export default defineComponent({
  data: () => ({
    modules: [Pagination],
    breakpoints: {
      // when window width is >= 320px
      0: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      650: {
        slidesPerView: 2,
        spaceBetween: 50,
      },
      1000: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    },
    news: { data: [] },
  }),
  mounted() {
    this.loadNews();
  },
  methods: {
    loadNews() {
      this.$http
        .get("api/v1/news?perPage=6&orderBy[datetime]=DESC")
        .then((response) => {
          this.news = response.data;
        });
    },
  },
  components: { Swiper, SwiperSlide },
});
