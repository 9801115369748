
import { defineComponent } from "vue";
import MainImage from "@/components/home/MainImage.vue";
import QuickData from "@/components/home/QuickData.vue";
import MyCity from "@/views/home/MyCity.vue";

export default defineComponent({
  components: {
    MainImage,
    QuickData,
    MyCity,
  },
});
