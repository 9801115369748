
import { defineComponent } from "vue";
import CustomButton from "@/components/customComponents/CustomButton.vue";

export default defineComponent({
  data: () => ({
    origin: "",
    destination: "",
  }),
  methods: {
    search() {
      if (this.origin === "" || this.destination === "") {
        return;
      }
      let url =
        "https://www.google.com/maps/dir/?api=1&origin=" +
        this.origin +
        "&destination=" +
        this.destination +
        "&travelmode=transit";
      window.open(url, "_blank");
    },
  },
  components: { CustomButton },
});
