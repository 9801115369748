<template>
  <div class="registration">
    <h3>{{ $t("sign_up_registration.registered") }}</h3>
    <p>
      {{ $t("sign_up_registration.visit") }}
    </p>
    <CustomButton
      :label="$t('sign_up_registration.signup')"
      add-class="white"
    />
  </div>
</template>

<script>
import CustomButton from "@/components/customComponents/CustomButton.vue";

export default {
  name: "SignUpRegistration.vue",
  components: { CustomButton },
};
</script>

<style scoped></style>
