<template>
  <div class="wp1200 withPadding">
    <h2 class="mainTitle pages">Politika zasebnosti</h2>

    <div>
      <p>
        Informacije o obdelavi osebnih podatkov za mobilno aplikacijo »Logatec
        Supermesto« (v nadaljevanju: aplikacija) so pripravljene v skladu z
        zahtevami Uredbe (EU) 2016/679 Evropskega parlamenta in Sveta z dne 27.
        Aprila 2016 o varstvu posameznikov pri obdelavi osebnih podatkov in
        prostem pretoku takih podatkov ter o razveljavitvi Direktive 95/46/ES (v
        nadaljevanju: <strong>GDPR</strong>).
      </p>
      <p>
        S sprejemanjem splošnih pogojev za uporabo aplikacije »Logatec
        Supermesto« in tega dokumenta, soglašate z zbiranjem, uporabo, obdelavo,
        shranjevanjem in razkritjem podatkov v skladu s to politiko zasebnosti.
      </p>
      <p>
        Osebne podatke, ki jih zbiramo, uporabljamo, obdelujemo in shranjujemo,
        uporabljamo le za zagotavljanje in izboljšanje naših storitev. Vaših
        osebnih podatkov ne bomo uporabljali, delili ali razkrivali tretjim
        osebam, razen kot je opisano v tej politiki zasebnosti.
      </p>
    </div>

    <div>
      <h2>1. Splošno</h2>
      <h3>1.1. Upravljalec</h3>
      <p>
        <strong>Upravljavec</strong> osebnih podatkov je
        <a href="https://www.logatec.si" target="_blank">Občina Logatec</a>,
        Tržaška cesta 50 a, 1370 Logatec (v nadaljevanju: občina). Upravljavec
        je dosegljiv tudi preko kontaktnega naslova: <strong>Tel.:</strong>
        <a href="tel:017590600">(01)7590600</a> <strong>Fax.:</strong>
        <a href="tel:017590620">(01)7590620</a> <strong>Email:</strong>
        <a href="mailto:obcina.logatec@logatec.si">obcina.logatec@logatec.si</a>
        . Zunanji pooblaščeni izvajalec, ki upravlja s platformo po pooblastilu
        občine ter v skladu s pogodbo o obdelavi osebnih podatkov, je podjetje
        <a href="https://www.t-2.net" target="_blank">T-2</a> d.o.o., Verovškova
        64A, 1000 Ljubljana.
      </p>
      <h3>1.2. Pooblaščena oseba za varstvo osebnih podatkov (DPO)</h3>
      <p>
        Občina je imenovala pooblaščeno osebo za varstvo osebnih podatkov, s
        katero lahko stopite v stik glede vseh vprašanj, poveznih z vašimi
        osebnimi podatki in uresničevanjem vaših pravic na podlagi 37. člena
        GDPR.
      </p>
      <ol>
        Zahteve v zvezi z vašimi pravicami na področju varstva osebnih podatkov,
        pritožbe ali vprašanja s tega področja nam lahko posredujete:
        <li>
          na naš naslov: Občina Logatec, Tržaška cesta 50 a, 1370 Logatec,
          <a href="mailto:obcina.logatec@logatec.si"
            >obcina.logatec@logatec.si</a
          >
          ,
        </li>
        <li>
          na naš elektronski naslov DPO:
          <a href="mailto:info@opf.si">info@opf.si</a>
        </li>
        <li>osebno na naslovu občine (glavna pisarna).</li>
      </ol>
      <h3>1.3. Uvodna določila o zagotavljanju varstva podatkov</h3>
      <p>
        Želimo, da bi se pri uporabi naše aplikacije počutili dobro in varno ter
        da bi naše izvajanje varovanja podatkov spoznali kot odgovorno in
        kvalitetno. Zagotavljamo vam, da smo zaupanja vreden upravljavec osebnih
        podatkov, ki se zaveda pomena varstva zasebnosti. Varnost podatkov pred
        morebitnimi kršitvami varujemo tudi z ustreznim razvojem informacijske
        tehnologije. Že v samem procesu razvoja aplikacije smo, v sodelovanju z
        zunanjim izvajalcem, zagotovili vse potrebno za skladnost s pomembnimi
        načeli varstva podatkov, ki jih zahteva GDPR. Tako je bila izdelana tudi
        <strong>ustrezna ocena učinkov na zasebnost</strong> (DPIA).
      </p>
      <p>
        Zagotavljamo, da bomo visokim standardom varstva vaše zasebnosti sledili
        tudi v bodoče.
      </p>
      <p>
        V nadaljevanju dokument vsebuje izjavo o varstvu osebnih podatkov, ki
        vsebuje informacije s katerimi občina zagotavlja transparentnost in
        obveščenost vseh posameznikov, ki uporabljajo aplikacijo, v skladu z
        zahtevami GDPR. V primeru dodatnih vprašanj smo dosegljivi preko
        kontaktnega naslova, ki je naveden v poglavju 1.2. tega dokumenta.
      </p>
    </div>

    <div>
      <h2>2. Nameni obdelave, pravne podlage in roki hrambe</h2>
      <p>
        Vaše osebne podatke obdelujemo zato, da lahko izpolnjujemo naše
        obveznosti v skladu s pogoji uporabe aplikacije (člen 6 (1b)GDPR)
        aplikacije, ki jih sprejmete ob registraciji . Poleg tega lahko
        obdelujemo osebne podatke na podlagi vaše privolitve (člen 6(1)(a) GDPR)
        ali na podlagi našega zakonitega interesa (člen 6 (1f) GDPR) na mestih
        in v obsegu, kot je pojasnjeno v tem dokumentu.
      </p>
      <p>
        Aplikacijo lahko uporabljajo samo registrirani uporabniki, ki so
        dopolnili 15 let. Potrditev starosti je predvidena v postopku
        registracije, ob potrditvi Splošnih pogojev in Politike o zasebnosti.
      </p>
      <p>
        Posredovanje vaših osnovnih kontaktnih podatkov je obvezno le v postopku
        registracije.
      </p>
      <p>
        Če ne boste posredovali vseh osebnih podatkov, morda ne boste mogli
        izkoristiti nekaterih funkcij aplikacij. Razen tega za vas ne bo nobenih
        posledic.
      </p>
      <p>
        Osebnih podatkov posameznikov, ki se niso registrirali, občina ne
        obdeluje.
      </p>
      <h3>2.1. Splošno o namenih obdelave</h3>
      <ol class="list-dash">
        Obdelava podatkov se v okviru uporabe aplikacije deli na tri glavne
        kategorije:
        <li>
          Pri prenosu aplikacije se osebni podatki prenesejo na ustrezno
          trgovino z aplikacijami (App Store ali Google Play).
        </li>
        <li>
          Da bi vam omogočili raznolike funkcije, ki jih nudi aplikacija, npr.
          iskanje primernih parkirnim mest v vaši bližini ali posredovanje slik,
          potrebujemo dostop do različnih funkcij in senzorjev vaše mobilne
          naprave.
        </li>
        <li>
          Z uporabo aplikacije se med vašo mobilno napravo in našim strežnikom
          izmenjujejo različne informacije. Tukaj so lahko to tudi osebni
          podatki. Tako pridobljene informacije se med drugim uporabljajo za
          izboljšanje funkcionalnosti aplikacije, izvedbo storitev, ki jih nudi
          aplikacija in posredovanje koristnih informacij (preko potisnih
          sporočil, e-pošte ali GSM), ki so nekomercialne narave.
        </li>
      </ol>
      <h3>2.2. Prenos aplikacije v ustrezni spletni trgovini</h3>
      <ol class="list-dash">
        Pri prenosu naše aplikacije se preko določenega ponudnika (Apple App
        Store ali Google Play) samodejno obdelujejo predvsem naslednji podatki v
        spletni trgovini, in sicer:
        <li>
          uporabniško ime, elektronski naslov, številka stranke, čas prenosa
          aplikacije, informacija o morebitnem plačilu in koda posamezne
          naprave.
        </li>
      </ol>
      <ol class="list-dash">
        Na takšno zbiranje podatkov ne moremo vplivati, zato za obdelavo ne
        moremo prevzeti odgovornosti. Več informacij o varovanju podatkov
        določenega ponudnika spletne trgovine pridobite pri ponudnikih in sicer:
        <li>
          Trgovina Google Play:
          <a href="https://policies.google.com/privacy?hl=sl" target="_blank"
            >https://policies.google.com/privacy?hl=sl</a
          >
        </li>
        <li>
          App App Store:
          <a href="https://www.apple.com/legal/privacy/sl/" target="_blank"
            >https://www.apple.com/legal/privacy/sl/</a
          >
        </li>
      </ol>
      <h3>2.3. Uporaba aplikacije</h3>
      <h4>2.3.1. Namen obdelave podatkov/pravna podlaga:</h4>
      <ol class="list-dash">
        Ko uporabljate aplikacijo, se podatki o vaši mobilni napravi, kot so na
        primer IP naslov, ID naprave, vrsta operacijskega sistema, datum in
        obdobje uporabe aplikacije ter sama uporaba aplikacije shranjujejo v
        tako imenovane »Log datoteke« za naslednje namene:
        <li>
          Izboljšanje varnosti in preprečevanje vdorov v informacijske sisteme,
        </li>
        <li>preprečevanje zlorab in goljufij,</li>
        <li>
          izvajanje analitike za izboljšanje storitev (podatki se obdelujejo na
          način, ki ne omogoča identifikacije posameznika),
        </li>
        <li>
          posredovanje potisnih sporočil (obdeluje se posebni »žeton« za ta
          namen).
        </li>
      </ol>
      <p>Pravno podlago za naštete namene predstavlja člen 6 (1f) GDPR.</p>
      <h4>
        2.3.2. Trajanje shranjevanja/kriteriji za določitev trajanja
        shranjevanja:
      </h4>
      <p>
        Podatki, ki se obdelujejo za namen zagotavljanja varnosti se hranijo za
        obdobje tridesetih dni, nato pa se samodejno izbrišejo.
      </p>
      <p>
        Anonimizirani podatki, ki jih obdelujemo za namene analitike ter za
        izboljšanje uporabniške izkušnje in storitev aplikacije, bomo obdelovali
        izključno do poteka namena izvajanja analize in ugotavljanja pomembnih
        dejstev za izboljšanje naših storitev.
      </p>
      <h3>2.4. Dostop do funkcij in senzorjev mobilne naprave</h3>
      <h4>2.4.1. Namen obdelave podatkov/pravna podlaga:</h4>
      <p class="extra-bold">Podatki o lokaciji</p>
      <p>
        Če ste se v okviru uporabe naše aplikacije oziroma v nastavitvah vaše
        mobilne naprave strinjali s tako imenovano »geolokacijo« preko dialoga
        „Dovoljenje za dostop“, uporabljamo to funkcijo, da vam lahko ponudimo
        storitve v zvezi z vašo trenutno lokacijo – t.i prilagojena sporočila
        (kot na primer prosta parkirna mesta v občini, zapore na cestah in
        podobno, ki so razvrščena glede na oddaljenost od trenutne lokacije vaše
        naprave). Vaš podatek o lokaciji uporabimo v okviru funkcije
        „posredovanja aktualnih sporočil in prikaza prilagojenih vsebin v
        aplikaciji«.
      </p>
      <p class="extra-bold">Kamera (zajemanje slik in videoposnetkov)</p>
      <p>
        Kamera vaše mobilne naprave se bo uporabljala za zajem slik iz vaše
        naprave (npr. vaše fotografije ob izpolnitvi profila ali na primer, če
        boste želeli občini posredovati druge fotografije v okviru funkcije
        »povej mestu«).
      </p>
      <p>
        Pravna podlaga za obdelavo je vaše dovoljenje v skladu s členom 6 (1a)
        GDPR.
      </p>
      <h4>
        2.4.2. Trajanje shranjevanja/kriteriji za določitev trajanja
        shranjevanja:
      </h4>
      <p>
        Vaši trenutni podatki o vaši lokaciji se po zaprtju aplikacije nemudoma
        prenehajo hraniti in obdelovati.
      </p>
      <h3>2.5. Registracija uporabnika in oblikovanje uporabniškega profila</h3>
      <h4>2.5.1. Namen obdelave podatkov/pravna podlaga:</h4>
      <p>
        a) <strong>Registracija</strong> se izvede z namenom uporabe aplikacije
        in vaše identifikacije. Izbrani kontaktni naslov (e-pošta ali GSM
        številka) in geslo, se uporabljajo za namen ponovne prijave v
        aplikacijo.
      </p>
      <ol class="list-dash">
        Aplikacije ni možno uporabljati brez predhodne registracije, potrditve
        splošnih pogojev ter politike zasebnosti in izjave, da je uporabnik star
        15 let ali več. Omogočeni so trije nivoji uporabnikov in sicer:
        <li>
          Nivo I – »uporabnik«: Uporabnik v postopku registracije vpiše
          naslednje osebne podatke: <strong>ime in priimek</strong> in
          <strong>e-poštni naslov ali GSM</strong> številko ter
          <strong>geslo</strong>. Navedeni podatki so obvezni. Po vnosu podatkov
          (ter predhodni potrditvi splošnih pogojev, te politike zasebnosti in
          izjave o starosti 15 ali več let) lahko uporabnik prične uporabljati
          aplikacijo. Uporabniki na tem nivoju registracije (brez verifikacije
          profila) lahko uporabljajo omejeno število storitev, ki jih nudi
          aplikacija, zato je priporočljivo, da se izvede verifikacija oziroma
          potrditev (Nivo 2/ Nivo 3).
        </li>
        <li>
          Nivo II - »verificirani uporabnik«: Uporabnik v postopku registracije
          vpiše naslednje osebne podatke: <strong>ime in priimek</strong>,
          <strong>e-poštni naslov ali GSM</strong> številko ter
          <strong>geslo</strong>. Navedeni podatki so obvezni. Po vnosu podatkov
          (ter predhodni potrditvi splošnih pogojev, te politike zasebnosti in
          izjave o starosti 15 ali več let) uporabnik izvede verifikacijo
          (potrditev) preko izbranega kontaktnega kanala. Po izvedeni
          verifikaciji profila lahko uporabnik prične z uporabo aplikacije.
        </li>
        <li>
          Nivo II - »napredni verificirani uporabnik«: Uporabnik v postopku
          registracije vpiše naslednje osebne podatke:
          <strong>ime in priimek</strong>,
          <strong>e-poštni naslov <u>in</u> GSM številko</strong> ter
          <strong>geslo</strong>. Navedeni podatki so obvezni. Po vnosu podatkov
          (ter predhodni potrditvi splošnih pogojev, te politike zasebnosti in
          izjave o starosti 15 ali več let) uporabnik izvede verifikacijo
          (potrditev) preko dveh izbranih kontaktnih kanalov. Po izvedeni
          napredni verifikaciji lahko uporabnik uporablja vse storitve mobilne
          aplikacije.
        </li>
      </ol>
      <p>
        Za namen identifikacije uporabnika se obdelujejo osebni podatki o imenu
        in priimku, e-poštnem naslovu ali GSM številki ter geslu. V primeru, da
        uporabnik profil verificira, se hrani tudi podatek o datumu verifikacije
        oziroma potrditve.
      </p>
      <p>
        b) Z namenom, da se v polni meri lahko uporabljajo storitve, ki jih nudi
        aplikacija, uporabniki lahko z drugimi osebnimi podatki,
        <strong>ki niso obvezni</strong>, dopolnijo
        <strong>uporabniški profil.</strong>
      </p>
      <p>
        Uporabnik lahko (poleg obveznih podatkov v postopku registracije) v svoj
        profil vpiše ali posreduje druge osebne podatke, ki izboljšujejo moč
        profila in s katerimi uporabnik pridobi možnost, da uporablja vse
        napredne storitve aplikacije.
      </p>
      <ol class="list-dash">
        <li>
          »Profilni« fotografiji: za namen personalizacije profila (fotografija
          ni namenjena identifikaciji!).
        </li>
        <li>
          Naslovu bivališča (ulica, hišna številka, kraj, poštna številka in
          država): podatek je pomemben za uporabo določenih funkcionalnosti, ki
          so povezane z lokacijo bivališča (npr. odvoz kosovnih odpadkov,
          prekinitev oskrbe vode ipd.)
        </li>
        <li>
          Datum rojstva (datum in letnica rojstva): podatek bo izboljšal moč
          profila, saj bo lahko uporabnik prejemal čestitke ob rojstnem dnevu
          ali druga, glede na rojstni podatek, prilagojena aktualna sporočila
          občine.
        </li>
        <li>
          Spolu: podatek bo izboljšal moč profila, saj bo tako omogočeno
          prejemanje prilagojenih (glede na spol) obvestil občine.
        </li>
        <li>
          Registrski oznaki vozila: podatek bistveno izboljšuje moč profila, saj
          lahko s tem uporabnik enostavno izvede proces plačila parkirnine.
        </li>
        <li>
          Seznamu priljubljenih lokacij: podatek je v pomoč uporabniku, saj se
          tako omogoči hitrejši in lažji dostop do lokacij, ki jih pogosto
          uporablja.
        </li>
        <li>
          Podatki o plačilnem sredstvu: uporabnik pridobi možnost, da enostavno
          in hitro izvede plačilo (na primer za parkiranje) z izbiro plačilnega
          sredstva, ki ga predhodno vpiše v svoj profil. Podatki o plačilnem
          sredstvu se ne shranjujejo v aplikaciji, temveč jih hrani institucija,
          ki je pooblaščena za obdelavo in upravljanje s transakcijami
          kartičnega poslovanja. Ob vnosu podatkov o kartici se ustvari t.i.
          unikatni žeton, preko katerega je zagotovljeno, da je izvedba plačila
          varna, skladna z zakonodajo in zahtevami najnovejših standardov
          kartičnih plačil.
        </li>
      </ol>
      <p>
        V aplikaciji se obdelujejo tudi podatki o zgodovini klepeta z občino,
        posredovanju uporabnikovih obvestil in fotografij ter zgodovini
        sodelovanja uporabnika v anketah. V primeru, da se izvajajo preko
        aplikacije tudi plačilne storitve, se hrani tudi zgodovina izvedenih
        plačil.
      </p>
      <p>
        Obdelava podatkov temelji na pravni podlagi pogodbenega razmerja v
        skladu s členom 6 (1b) GDPR (ob predhodni potrditvi splošnih pogojev in
        politike zasebnosti).
      </p>
      <p>
        Vnos podatkov v profil ni obvezen (razen osnovnih podatkov za namen
        registracije uporabnika).
      </p>
      <h4>
        2.5.2. Trajanje shranjevanja/kriteriji za določitev trajanja
        shranjevanja:
      </h4>
      <p>
        Ob registraciji oz. oblikovanju profila se vsem uporabnikom
        <strong>dodeli enolični ID</strong>. Enolični ID služi kot identifikator
        vira podatkov in samega uporabniškega računa. Na ta način se izvede
        psevdonimizacija uporabnika, kar pomeni, da obdelava podatkov ne omogoča
        neposredne identifikacije uporabnika.
      </p>
      <p>
        Podatki o uporabniku se hranijo do izbrisa profila v aplikaciji oz.
        največ dve leti od zadnje prijave v aplikacijo.
      </p>
      <h3>2.6. Obveščanje registriranih uporabnikov</h3>
      <h4>2.6.1. Namen obdelave podatkov/pravna podlaga:</h4>
      <p class="extra-bold">
        Registrirani uporabniki aplikacije so obveščeni o aktualnih dogodkih v
        občini preko potisnih sporočil (na mobilni napravi), e-poštnega naslova
        ali GSM številke
      </p>
      <p>
        Po izvedeni registraciji uporabnik prične prejemati
        <strong
          >potisna sporočila in druga aktualna sporočila občine preko e-poštnega
          naslova ali GSM številke.</strong
        >
        Vsebina sporočil je nekomercialne narave, povezana z aktualnimi dogodki
        v občini in drugimi informacijami. V določenih primerih so lahko
        informacije prilagojene uporabniku (personalizirana sporočila na podlagi
        trenutne lokacije, na podlagi uporabe aplikacije ter storitev oziroma
        funkcionalnosti, ki jih nudi aplikacija).
      </p>
      <p>Sporočila niso namenjena trženju!</p>
      <p>
        <strong>Potisna sporočila</strong> so sporočila, ki se prikazujejo na
        mobilni napravi – na zaklenjenem zaslonu, domačem zaslonu ter pri
        uporabi drugih aplikacij – ne da bi imel uporabnik odprto aplikacijo. S
        klikom na potisno sporočilo se aplikacija odpre, v njej pa je prikazano
        ustrezno sporočilo. S potisnimi sporočili in drugimi oblikami obveščanja
        želimo vzpostaviti učinkovito obveščanje uporabnikov o novicah,
        dogodkih, občinskih storitvah, splošnih informacijah, personaliziranih
        informacijah, izrednih dogodkih, splošnih nevarnostih, novih
        funkcionalnostih aplikacije in podobno. Za namen ugotavljanja kdo in s
        katero napravo je prijavljen na potisna sporočila, zbiramo žetone za
        potisna sporočila.
        <strong
          >Ko v nastavitvah izklopite potisna sporočila, potisnih sporočil ne
          boste več prejemali in žeton se deaktivira do ponovnega
          vklopa.</strong
        >
      </p>
      <p>
        Potisna sporočila prejemajo vsi registrirani uporabniki aplikacije, ki
        imajo v nastavitvah naprave oziroma v sklopu »Moja soglasja« vklopljeno
        prejemanje potisnih sporočil. Vsebina posameznih sklopov obvestil pa se
        lahko razlikuje glede na stopnjo verifikacije profila. Z namenom, da se
        doseže najvišji nivo kvalitete storitev, ki so povezane z obveščanjem
        uporabnikov, se izvaja segmentacija uporabnikov (na primer glede na
        spol, lokacijo bivališča, starost in podobno).
      </p>
      <p>
        Za zgoraj navedene namene se obdelujejo osebni podatki o mobilni napravi
        in kontaktni podatki o registriranem uporabniku ter podatki o uporabi
        aplikacije in storitev za namen posredovanja prilagojenih sporočil.
        Podatki o lokaciji se, za namen posredovanja personaliziranih sporočil,
        obdelujejo samo v primeru predhodne privolitve (če je funkcija
        »geolokacija« preko mobilne naprave vklopljena oz. ste za to dali
        dovoljenje).
      </p>
      <p>
        Posredovanje vseh oblik sporočil (potisnih in drugih) temelji na pravni
        podlagi pogodbenega razmerja v skladu s členom 6 (1b) GDPR. Obdelava
        osebnih podatkov za namen posredovanja sporočil se izvaja samo v
        primeru, če se uporabnik registrira in ob tem potrdi splošne pogoje in
        politiko zasebnosti ter potrdi, da je star 15 ali več let. Za obdelavo
        in prikaz sporočil se uporabljajo psevdonimizirani podatki (enolični
        ID). V nobenem primeru se podatki uporabnikov ne uporabljajo za
        identifikacijo.
      </p>
      <p class="extra-bold">Možnost spremembe / Opt-Out</p>
      <p>
        Potisna sporočila naše aplikacije boste prejemali le, če ste se
        registrirali v aplikaciji.
        <strong
          >Če naših potisnih sporočil ne želite več prejemati, lahko prejemanje
          deaktivirate tako, da spremenite sistemske nastavitve o pošiljanju
          potisnih sporočil na svoji mobilni napravi ali način prejemanja
          obvestil enostavno spremenite v nastavitvah naše aplikacije, v vašem
          uporabniškem profilu.</strong
        >
      </p>
      <p>
        Podobno velja za prejemanje sporočil preko e-poštnega naslova ali GSM.
        Če sporočil občine ne želite več prejemati, to lahko spremenite ali
        deaktivirate tako, da spremembo vnesete v nastavitvah aplikacije, v
        vašem uporabniškem profilu v sklopu »Moja soglasja«.
      </p>
      <h4>
        2.6.2. Trajanje shranjevanja / kriteriji za določitev trajanja
        shranjevanja:
      </h4>
      <p>
        Podatki se za namen posredovanja sporočil hranijo do izbrisa aplikacije
        oziroma do spremembe uporabnika (na primer deaktivacije funkcije
        posredovanja sporočil v uporabniškem profilu) glede nastavitev
        zasebnosti v sklopu »Moja soglasja«.
      </p>
      <h3>2.7. Izvedba plačil uporabnika</h3>
      <h4>2.7.1. Namen obdelave podatkov/pravna podlaga:</h4>
      <p class="extra-bold">
        a) Registrirani uporabniki lahko preko aplikacije izvedejo postopek
        plačila
      </p>
      <p>
        Za namen izvedbe plačil (na primer za namen parkiranja) zbiramo samo
        nujne podatke o plačilnem sredstvu oziroma t.i. žeton, katerega
        potrebujemo za izvedbo plačila storitve, lahko pa tudi podatke o vašem
        vozilu (registrska oznaka) za potrebe identifikacije vozila, za katero
        je bilo plačilo parkirnine izvedeno..
      </p>
      <p>
        Glede plačilnih sredstev, se natančni zbrani podatki razlikujejo glede
        na način plačila, vendar vključujejo podatke, kot so vrsta plačilnega
        sredstva, in zgodovino izvedenih plačil.
      </p>
      <p>
        Hramba podatkov se zagotavlja z namenom, da lahko uporabnik spremlja
        zgodovino plačil in tako v največji možni meri uporablja aplikacijo.
        Hranijo se podatki o transakciji, enolični ID, ime plačilnega mesta (na
        primer parkirišče 101), ponudniku storitve, ceni in času izvedbe.
      </p>
      <p>
        Obdelava temelji na pravni podlagi pogodbenega razmerja z uporabnikom v
        skladu s členom 6 (1b) GDPR. Podatki o transakciji (račun) se lahko
        hranijo tudi z namenom, da se zadosti zakonskim obveznostim in sicer v
        skladu s členom 6 (1c) GDPR.
      </p>
      <p>
        Podatkov o bančni kartici ne hranimo. V procesu izvedbe plačila se
        vpisani podatki prenesejo v sistem institucije, ki je odgovorna in
        pooblaščena za upravljanje iz obdelavo podatkov za potrebe izvajanja
        kartičnih plačil, skladno z zakonodajo in najvišjimi standardi. V
        nadaljevanju se ustvari t.i. žeton, ki omogoča izvedbo transkacije s
        kartičnim plačilom med uporabnikom in ponudnikom storitve.
      </p>
      <h4>
        2.7.2. Trajanje shranjevanja / kriteriji za določitev trajanja
        shranjevanja:
      </h4>
      <p>
        Podatki, ki se hranijo na podlagi pogodbenega razmerja se hranijo do
        izbrisa aplikacije oz. največ dve leti od zadnje prijave. Zaradi
        zakonskih zahtev, se lahko podatki o izvedenem plačilu hranijo 10 let.
      </p>
    </div>

    <div>
      <h2>3. Uporabniki / kategorije uporabnikov</h2>
      <p>
        Vaše osebne podatke načeloma obdelujejo le naši zaposleni, ki so
        zavezani k zaupnosti in jih redno izobražujemo in usposabljamo za varno
        ravnanje s podatki.
      </p>
      <p>
        V nekaterih primerih pa vaše podatke v našem imenu in po naših navodilih
        obdelujejo naši zunanji izvajalci. Z zunanjimi izvajalci vedno sklenemo
        ustrezno pogodbo o obdelavi podatkov, s katero jih zavežemo k enaki ali
        višji stopnji varnosti vaših podatkov. Zunanji izvajalci vaših osebnih
        podatkov ne obdelujejo za lastne namene.
        <strong
          >Vedno izbiramo zunanje izvajalce znotraj Evropske unije (EU). Kadar
          obdelavo osebnih podatkov za na izvaja izvajalec izven EU, poskrbimo
          za ustrezne zaščitne ukrepe.</strong
        >
      </p>
      <ol class="list-dash">
        Zunanji izvajalci za nas izvajajo zlasti:
        <li>poštne storitve in zagotavljanje e-komunikacijskih orodij;</li>
        <li>
          zagotavljanje storitev informacijske tehnologije za naše nemoteno
          poslovanje;
        </li>
        <li>upravljanje platforme – aplikacije »Logatec Supermesto«;</li>
        <li>
          strokovno analizo podatkov in analizo uporabniške izkušnje za pripravo
          prilagojenih obvestil;
        </li>
        <li>hrambo podatkov:</li>
        <li>plačilne storitve.</li>
      </ol>
      <p>
        Za potisna sporočila uporabljamo Googlov Firebase Cloud Messaging. Za
        več informacij si preberite njihovo
        <a href="https://firebase.google.com/support/privacy" target="_blank"
          >politiko zasebnosti</a
        >.
      </p>
      <p>
        V drugih primerih posredujemo vaše osebne podatke drugim prejemnikom
        zgolj v primeru, če za to obstaja ustrezna pravna podlaga ali ste v to
        privolili sami. Vsako privolitev, ki ste jo dali, lahko kadarkoli
        umaknete, prekinitev privolitve velja za v prihodnje, od trenutka umika
        naprej.
      </p>
      <p>
        Državnim organom bomo razkrili vaše podatke zgolj v skladu z zakonskimi
        obveznostmi ali na uradno zahtevo oziroma na zahtevo sodišča, in to
        zgolj v tolikšni meri, kot to dovoljuje zakonodaja o varstvu podatkov.
      </p>
      <p>
        V določenih primerih (na primer, če boste preko aplikacije izvedli
        plačilo za namen parkiranja ali drugih komunalnih storitev) bomo lahko
        vaše podatke posredovali tudi tretjim osebam, ki so samostojni
        upravljavci osebnih podatkov (javna podjetja, lastniki parkirnih mest in
        podobno).
      </p>
    </div>

    <div>
      <h2>4. Vaše pravice</h2>
      <h3>4.1. Pravica do informacij v skladu z 15. členom GDPR</h3>
      <ol class="list-dash">
        Na zahtevo imate pravico pridobiti informacije o osebnih podatkih,
        shranjenih o vas v skladu s 15. členom 1. odstavek GDPR. To obsega:
        <li>
          namene, za katere se osebni podatki obdelujejo;- kategorije osebnih
          podatkov, ki se obdelujejo;- prejemnike ali kategorije prejemnikov, ki
          so jim bili osebni podatki razkriti ali pa še bodo razkriti;-
          načrtovano trajanje shranjevanja vaših osebnih podatkov ali, če
          specifični podatki niso na voljo, kriterije za določanje trajanja
          shranjevanja;- pravico do popravka ali izbrisa vaših osebnih podatkov,
          pravico do omejitve obdelave s strani upravljavca ali pravico do
          ugovora proti tej obdelavi;- pravico do pritožbe pri nadzornem
          organu;- vse dostopne informacije o izvoru podatkov, če osebni podatki
          niso bili pridobljeni pri zadevni osebi;- avtomatizirano odločanje,
          vključno s profiliranjem v skladu s 1. in 4. odstavkom 22. člena GDPR
          in – vsaj v teh primerih – sporočilne informacije o vključeni logiki
          ter doseg in predviden vpliv take obdelave na zadevno osebo.
        </li>
      </ol>
      <h3>4.2. Pravica do popravka v skladu s 16. členom GDPR</h3>
      <p>
        Vaše podatke nam posredujete oziroma vpišete v aplikacijo sami in imate
        tudi možnost, da jih tudi ustrezno popravite.
      </p>
      <p>
        Postopek popravka v skladu s 16. členom GDPR izvedemo tudi mi, če nam
        posredujete zahtevo za popravek napačnih podatkov. Z upoštevanjem
        namenov obdelave imate pravico, da zahtevate dopolnitev nepopolnih
        osebnih podatkov – tudi z dopolnilno izjavo.
      </p>
      <h3>4.3. Pravica do izbrisa v skladu s 17. členom GDPR</h3>
      <p>
        Vaše osebne podatke zbrišemo takoj, ko jih ne potrebujemo za namene
        obdelave za katere so bili zbrani. Vaše osebne podatke izbrišemo tudi v
        primeru, ko izrazite nasprotovanje določeni obdelavi osebnih podatkov na
        podlagi zakonitih interesov, če nimamo utemeljenih razlogov za nadaljnjo
        obdelavo. Prav tako izbrišemo vaše podatke, če umaknete svoje soglasje
        za obdelavo in za obdelavo ni druge pravne podlage. V nekaterih
        primerih, npr. če je predpisan zakonski rok za hrambo določenih
        podatkov, bodo vaši podatki sprva zablokirani in izbrisani po tem, ko
        takšen rok poteče.
      </p>
      <ol class="list-dash">
        Na podlagi vaše posebne vloge oz. zahteve pa lahko zahtevate takojšen
        izbris svojih osebnih podatkov na osnovi vsaj enega od naslednjih
        razlogov:
        <li>
          osebni podatki za namene, za katere so bili pridobljeni in obdelani,
          niso več potrebni;- preklicujete svoje soglasje, na katerem temelji
          obdelava v skladu s členom 6 (1a) ali členom 9 (2a) GDPR, za katero pa
          ni legitimne pravne podlage za obdelavo;- proti obdelavi ste podali
          ugovor v skladu s 1. ali 2. odstavkom 21. člena GDPR, v tem primeru pa
          legitimni razlogi za obdelavo niso na voljo v skladu s 1. odstavkom
          21. člena GDPR;- osebni podatki so se obdelovali nezakonito;- izbris
          osebnih podatkov je nujen za izpolnitev pravne obveznosti;- osebne
          podatke se je pridobivalo v zvezi s ponujenimi storitvami
          informacijske družbe v skladu s 1. odstavkom 8. člena GDPR.
        </li>
      </ol>
      <h3>4.4. Pravica do omejitve obdelave v skladu z 18. členom GDPR</h3>
      <p>
        Od nas lahko zahtevate, da omejimo obdelavo vaših osebnih podatkov
        (kadar oporekate točnosti osebnih podatkov, kadar je obdelava nezakonita
        ter v primerih, ko jih občina ne potrebuje več za obdelavo, temveč jih
        vi potrebujete za uveljavljanje, izvajanje ali obrambo vaših pravnih
        zahtevkov).
      </p>
      <h3>4.5. Pravica do prenosa podatkov v skladu s 20. členom GDPR</h3>
      <ol class="list-dash">
        Imate pravico do prejetja svojih osebnih podatkov v strukturirani,
        posodobljeni in strojno berljivi obliki, te podatke lahko tudi
        posredujete drugemu upravljavcu, če
        <li>
          obdelava temelji na soglasju v skladu s členom 6 (1a) ali členom 9
          (2a) GDPR ali pa na pogodbi v skladu s členom 6 (1b) GDPR,- obdelava
          pa se izvaja s pomočjo avtomatiziranega postopka.
        </li>
      </ol>
      <p>
        Pri uveljavljanju pravice do prenosa podatkov lahko od nas zahtevate, da
        osebne podatke neposredno posredujemo drugemu upravljavcu, če je to
        tehnično izvedljivo.
      </p>
      <p>
        Prenos vaših osebnih podatkov lahko izvedete tudi sami in sicer tako, da
        si podatke izvozite preko posebne funkcionalnosti, ki jo nudi
        aplikacija.
      </p>
      <h3>4.6. Pravica do ugovora v skladu z 21. členom GDPR</h3>
      <p>
        Z upoštevanjem predpostavk iz 1. odstavka 21. člena GDPR lahko obdelavi
        podatkov ugovarjate iz razlogov, ki izhajajo iz vaše posebne situacije.
      </p>
      <p>
        Navedena splošna pravica do ugovora velja za vse opisane namene
        obdelave, ki se izvajajo na podlagi člena 6 (1f) GDPR. K izvajanju tega
        splošnega ugovora smo zavezani samo, če nam za to navedete izjemno
        pomembne razloge, kot je npr. morebitna nevarnost za življenje ali
        zdravje.
      </p>
      <h3>4.7. Uveljavljanje pravic</h3>
      <p>
        Za uveljavitev pravic nam morate posredovati vaše ime in zadostne
        podatke, ki nam omogočajo, da vas nedvoumno prepoznamo v zvezi z
        uveljavljanjem pravice.
      </p>
      <p>
        Pravice v zvezi z vašimi osebnimi podatki lahko uveljavljate le vi
        osebno, druga oseba v vašem imenu pa zgolj na podlagi vašega pisnega
        pooblastila.
      </p>
      <ol>
        Zahtevo za uresničitev posamezne pravice lahko vložite na:
        <li>
          na naš naslov: Občina Logatec, Tržaška cesta 50 a, 1370 Logatec,
          <a href="mailto:obcina.logatec@logatec.si"
            >obcina.logatec@logatec.si</a
          >
          ,
        </li>
        <li>
          na naš elektronski naslov DPO:
          <a href="mailto:info@opf.si">info@opf.si</a>
        </li>
        <li>osebno na naslovu občine (glavna pisarna).</li>
      </ol>
      <p>
        Če menite, da občina vaše zahteve ni ustrezno obravnavala, se lahko
        pritožite pri nadzornem organu, ki je za območje Republike Slovenije
        Informacijski pooblaščenec, katerega kontaktni podatki so na voljo (<a
          href="https://www.ip-rs.si/o-pooblascencu/osebna-izkaznica/"
          >tukaj</a
        >).
      </p>
    </div>

    <div>
      <h2>5. Ukrepi za zavarovanje podatkov</h2>
      <p>
        <strong
          >V postopku razvoja aplikacije smo izvedli presojo učinkov na
          zasebnost (DPIA – 35. člen GDPR) in s tem upoštevali najpomembnejša
          načela za zagotavljanje skladnosti aplikacije z GDPR.</strong
        >
        Sprejeli smo tudi ustrezne tehnične in organizacijske ukrepe za
        zavarovanje vaših osebnih podatkov in njihovo zaščito pred nepooblaščeno
        ali nezakonito uporabo ali obdelavo ter proti naključni izgubi ali
        uničenju ali poškodovanju vaših osebnih podatkov. Skrbno in odgovorno
        izbiramo tudi naše zunanje izvajalce. Za zagotavljanje ustreznega nivoja
        skladnosti z GDPR ima občina imenovano tudi pooblaščeno osebo za varstvo
        osebnih podatkov (DPO).
      </p>
      <p>
        Za varnost vaših osebnih podatkov lahko veliko storite tudi sami, tako
        da na primer uporabljate le zaščitene brezžične povezave, redno
        posodabljate programsko opremo in protivirusno zaščito, oblikujete
        učinkovita oz. varna dostopna gesla (najmanj 8 znakov, jih redno
        spreminjate in ne delite z nikomur) in upoštevate nasvete za varno rabo
        interneta.
      </p>
    </div>

    <div>
      <h2>6. Avtomatizirano odločanje</h2>
      <p>
        Vaših osebnih podatkov ne uporabljamo za avtomatizirano strojno
        odločanje, kot je to mišljeno v členu 22 (1) GDPR.
      </p>
    </div>

    <div>
      <h2>7. Veljavnost politike</h2>
      <p>
        Pridržujemo si pravico, da to Politiko o zasebnosti kadarkoli
        spremenimo.
      </p>
      <p>
        V primeru spremembe bomo prenovljeno politiko objavili v mobilni
        aplikaciji. Za informacije o tem, kdaj je bila objavljena zadnja
        različica, prosimo, preverite datum objave na vrhu tega dokumenta.
      </p>
      <address>Občina Logatec</address>
      <address>Župan: Berto Menard</address>
    </div>
    <br />
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style scoped>
.extra-bold {
  font-family: proxima-nova-bold;
}

.list-dash > li {
  list-style-type: none;
}

.list-dash > li:before {
  display: inline-block;
  content: "-";
  width: 1rem;
}
</style>
