
import { defineComponent } from "vue";
import SidebarWrapper from "@/components/SidebarWrapper.vue";
import SidebarAirQuality from "@/components/airQuality/SidebarAirQuality.vue";
import MapAirQuality from "@/components/airQuality/MapAirQuality.vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import ProgressBar from "primevue/progressbar";
import AdvancedGaugeChart from "@/components/charts/AdvancedGaugeChart.vue";

interface AqRanges {
  index: Array<number>;
  pm10: Array<number>;
  pm25: Array<number>;
  o3: Array<number>;
  no2: Array<number>;
  so2: Array<number>;
}

export default defineComponent({
  data: () => ({
    sidebarComponent: SidebarAirQuality,
    mapComponent: MapAirQuality,
    sensor: null,
    aqRanges: {
      index: [50, 75, 100, 200],
      pm10: [40, 75, 100, 200],
      pm25: [20, 40, 80, 160],
      o3: [100, 180, 240, 480],
      no2: [100, 200, 400, 800],
      so2: [200, 350, 500, 1000],
    } as AqRanges,
  }),
  mounted() {
    this.loadAirQualitySensor();
  },
  methods: {
    loadAirQualitySensor() {
      if (
        Object.prototype.hasOwnProperty.call(this.$route.params, "id") === false
      ) {
        return;
      }
      this.$http
        .get("api/v2/air-quality/" + this.$route.params.id)
        .then((response) => {
          this.sensor = response.data;
        });
    },
    getValueColorClass(type: keyof AqRanges, value: number | null) {
      if (value === null) {
        return "sensorGreen";
      }
      let ranges = this.aqRanges[type];
      return value > ranges[2]
        ? "sensorRed"
        : value > ranges[1]
        ? "sensorOrange"
        : value > ranges[0]
        ? "sensorYellow"
        : "sensorGreen";
    },
    getSensorIndexText(value: number | null) {
      if (value === null) {
        return this.$t("air_quality.good");
      }
      return value > 100
        ? this.$t("air_quality.very_bad")
        : value > 75
        ? this.$t("air_quality.bad")
        : value > 50
        ? this.$t("air_quality.border")
        : this.$t("air_quality.good");
    },
  },
  computed: {
    tableDays() {
      const days = this.$moment.weekdaysShort();
      let dayIndex = new Date().getDay();
      let header = [];
      for (let i = 1; i < 8; i++) {
        dayIndex = dayIndex < 0 ? 6 : dayIndex;
        header.push(days[dayIndex]);
        dayIndex--;
      }
      return header;
    },
  },
  watch: {
    "$route.params.id"() {
      this.loadAirQualitySensor();
    },
  },
  components: {
    AdvancedGaugeChart,
    Accordion,
    AccordionTab,
    ProgressBar,
    SidebarWrapper,
  },
});
