import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewsCard = _resolveComponent("NewsCard")!
  const _component_SidebarWrapper = _resolveComponent("SidebarWrapper")!

  return (_openBlock(), _createBlock(_component_SidebarWrapper, {
    title: _ctx.$t('news.title'),
    sidebarComponent: _ctx.sidebarComponent
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.news.data, (article) => {
        return (_openBlock(), _createBlock(_component_NewsCard, {
          article: article,
          key: article.id
        }, null, 8, ["article"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["title", "sidebarComponent"]))
}