
import { defineComponent } from "vue";

import Dialog from "primevue/dialog";
import MapCustom from "@/components/MapCustom.vue";
import { Coordinates, Marker } from "@/typings/Marker";
import { QueryParameters } from "@/typings/QueryParameters";
import { Parking, ParkingFilter } from "@/typings/Parking";

export default defineComponent({
  data: () => ({
    showMap: false,
    center: { lat: 45.91831, lng: 14.229618 } as Coordinates,
    zoom: 15,
    markers: [] as Marker[],
  }),
  methods: {
    openMap() {
      this.loadParkings();
      this.showMap = true;
    },
    closeMap() {
      this.showMap = false;
    },
    updateCenter(value: Coordinates) {
      this.center = value;
      this.loadParkings();
    },
    updateZoom(value: number) {
      this.zoom = value;
      this.loadParkings();
    },
    loadParkings() {
      let params: QueryParameters & {
        latitude?: number;
        longitude?: number;
        radius?: number;
      } = {
        filters: this.getFilter(),
      };

      if (this.center !== null) {
        params.latitude = this.center.lat;
        params.longitude = this.center.lng;
        params.radius = Math.pow(2, 27 - this.zoom);
      }
      this.$http.get("api/v2/parkings", { params }).then((response) => {
        let parkingArray = response.data.data as Parking[];
        this.markers = parkingArray.map((parking) => ({
          id: parking.id,
          position: {
            lat: parseFloat(parking.latitude),
            lng: parseFloat(parking.longitude),
          },
          on_click_route_name: "ParkingById",
          type: "parking",
        }));
      });
    },
    getFilter(): ParkingFilter {
      let cityId = window.VUE_APP_CITY_ID || process.env.VUE_APP_CITY_ID;
      let filter: ParkingFilter = {
        city_id: cityId,
      };
      return filter;
    },
  },
  components: { MapCustom, Dialog },
});
