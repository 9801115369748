
import { defineComponent } from "vue";
import CalendarIcon from "@/components/customComponents/CalendarIcon.vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Pagination } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.css";

export default defineComponent({
  data: () => ({
    modules: [Pagination],
    breakpoints: {
      // when window width is >= 320px
      0: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      650: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      1000: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
    },
    events: { data: [] },
  }),
  mounted() {
    this.loadEvents();
  },
  methods: {
    loadEvents() {
      this.$http
        .get("api/v1/events?perPage=6&orderBy[datetime]=DESC")
        .then((response) => {
          this.events = response.data;
        });
    },
  },
  components: {
    CalendarIcon,
    Swiper,
    SwiperSlide,
  },
});
