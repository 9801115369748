
import ProgressBar from "primevue/progressbar";
//import Slider from "primevue/slider";
import InputSwitch from "primevue/inputswitch";

import { defineComponent } from "vue";
import Search from "@/components/Search.vue";
import { Parking, ParkingFilter } from "@/typings/Parking";
import { getOccupancy, getValueColorClass } from "@/services/Parking";
import { QueryParameters } from "@/typings/QueryParameters";
import { Paginated } from "@/typings/Paginated";
import InfinityScroll from "@/components/InfinityScroll.vue";

export default defineComponent({
  data: () => ({
    parkings: {
      data: [],
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    } as Paginated<Parking>,
    smartParking: false,
    searchTerm: "",
    showFilters: false,
  }),
  mounted() {
    this.loadParkings();
  },
  methods: {
    loadParkings(page = 1) {
      let params: QueryParameters = {
        filters: this.getFilter(),
        page,
      };
      this.$http.get("api/v2/parkings", { params }).then((response) => {
        if (page !== 1) {
          this.parkings.data = this.parkings.data.concat(response.data.data);
          this.parkings.current_page = response.data.current_page;
          return;
        }
        this.parkings = response.data;

        if (
          Object.prototype.hasOwnProperty.call(this.$route.params, "id") ===
            true ||
          this.parkings.data.length === 0
        ) {
          return;
        }

        let firstParking: Parking = this.parkings.data[0];
        this.$router.push({
          name: "ParkingById",
          params: { id: firstParking.id },
        });
      });
    },
    getFilter(): ParkingFilter {
      let cityId = window.VUE_APP_CITY_ID || process.env.VUE_APP_CITY_ID;
      let filter: ParkingFilter = {
        city_id: cityId,
      };
      if (this.smartParking) {
        filter.smart = 1;
      }
      if (this.searchTerm !== "") {
        filter.name = "%" + this.searchTerm + "%";
      }
      return filter;
    },
    getOccupancy(parking: Parking) {
      return getOccupancy(parking);
    },
    getValueColorClass(parking: Parking): string {
      const spaces =
        parking.spaces_regular +
        parking.spaces_accessible +
        parking.spaces_ev +
        parking.spaces_parent;
      const occupied =
        parking.spaces_regular_occupied +
        parking.spaces_accessible_occupied +
        parking.spaces_ev_occupied +
        parking.spaces_parent_occupied;
      return getValueColorClass(occupied, spaces);
    },
  },
  components: {
    InfinityScroll,
    Search,
    ProgressBar,
    //Slider,
    InputSwitch,
  },
});
