
import Navigation from "@/components/Navigation.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Header",
  components: { Navigation },
  setup() {
    const isMenuOpen = ref(false);
    return {
      isMenuOpen,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    updateLocale() {
      localStorage.setItem("locale", this.$i18n.locale);
      this.$moment.locale(this.$i18n.locale);
    },
  },
});
