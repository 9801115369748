
import { defineComponent } from "vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { EventCategory } from "@/typings/EventCategory";
import Calendar from "primevue/calendar";
import { LocationQueryValueRaw } from "vue-router";

interface EventQuery
  extends Record<
    string | number,
    string | number | LocationQueryValueRaw[] | null | undefined
  > {
  category_id?: string;
  from?: string;
  until?: string;
}

export default defineComponent({
  data: () => ({
    categories: [],
  }),
  mounted() {
    this.loadEventCategories();
  },
  methods: {
    loadEventCategories() {
      this.$http.get("api/v1/events/categories").then((response) => {
        this.categories = response.data;
      });
    },
    setCategory(category: EventCategory | null) {
      this.$router.push({
        name: "Events",
        query: {
          ...this.$route.query,
          category_id: category?.id,
        },
      });
    },
  },
  computed: {
    selectedCategoryId() {
      return Object.prototype.hasOwnProperty.call(
        this.$route.query,
        "category_id"
      ) && this.$route.query.category_id !== null
        ? parseInt(this.$route.query.category_id.toString())
        : null;
    },
    from: {
      get() {
        return Object.prototype.hasOwnProperty.call(
          this.$route.query,
          "from"
        ) && this.$route.query.from !== null
          ? this.$moment(this.$route.query.from).toDate()
          : null;
      },
      set(value: string) {
        let query: EventQuery = {
          ...this.$route.query,
          from: this.$moment(value).toISOString(),
        };
        if (this.until === null) {
          query.until = this.$moment(value).add(1, "months").toISOString();
        }
        this.$router.push({
          name: "Events",
          query,
        });
      },
    },
    until: {
      get() {
        return Object.prototype.hasOwnProperty.call(
          this.$route.query,
          "until"
        ) && this.$route.query.until !== null
          ? this.$moment(this.$route.query.until).toDate()
          : null;
      },
      set(value: string) {
        let query: EventQuery = {
          ...this.$route.query,
          until: this.$moment(value).toISOString(),
        };
        if (this.from === null) {
          query.from = this.$moment().toISOString();
        }
        this.$router.push({
          name: "Events",
          query,
        });
      },
    },
  },
  components: { Accordion, AccordionTab, Calendar },
});
