
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { WeatherModel, CurrentWeatherModel } from "@/models/weather/Weather";
import { defineComponent, ref } from "vue";
import { Weather as WeatherInterface } from "@/typings/Weather";
import WeatherChart from "@/components/charts/WeatherChart.vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Pagination } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.css";

export default defineComponent({
  setup() {
    const canvas = ref("canvas");
    const rive = (window as any).rive;

    return {
      canvas,
      rive,
    };
  },
  data: () => ({
    modules: [Pagination],
    breakpoints: {
      // when window width is >= 320px
      0: {
        slidesPerView: 4,
        spaceBetween: 0,
      },
      650: {
        slidesPerView: 6,
        spaceBetween: 0,
      },
      1000: {
        slidesPerView: 13,
        spaceBetween: 0,
      },
    },

    currently: {},
    daily: {
      data: [],
    },
  }),
  mounted() {
    this.loadEvents();
    new this.rive.Rive({
      src: "/rive/delno_oblacno.riv" /* menjava datoteke glede na vreme */,
      canvas: this.canvas,
      autoplay: true,
      animations: [] /* tip animacije */,
      layout: new this.rive.Layout({
        fit: "cover",
        alignment: "center",
      }),
    });
  },
  methods: {
    loadEvents() {
      let cityId = window.VUE_APP_CITY_ID || process.env.VUE_APP_CITY_ID;

      this.$http.get("api/v1/weather/" + cityId).then((response) => {
        this.currently = new CurrentWeatherModel(response.data.currently);
        this.daily.data = response.data.daily.data.map(
          (dailyData: WeatherInterface) => {
            let model = new WeatherModel(dailyData);
            model.filterHourly(response.data.hourly.data);
            return model;
          }
        );
      });
    },
  },
  components: { WeatherChart, Accordion, AccordionTab, Swiper, SwiperSlide },
});
