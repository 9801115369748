import { Parking } from "@/typings/Parking";

export function getOccupancy(parking: Parking): number {
  const all =
    parking.spaces_regular +
    parking.spaces_accessible +
    parking.spaces_ev +
    parking.spaces_parent;
  const occupied =
    parking.spaces_regular_occupied +
    parking.spaces_accessible_occupied +
    parking.spaces_ev_occupied +
    parking.spaces_parent_occupied;
  return Math.round((occupied / all) * 100);
}

export function getValueColorClass(occupied: number, spaces: number): string {
  if (spaces == 0) {
    return "sensorRed";
  }
  const ranges = [0.25, 0.5, 0.66];
  const value = occupied / spaces;
  return value > ranges[2]
    ? "sensorRed"
    : value > ranges[1]
    ? "sensorOrange"
    : value > ranges[0]
    ? "sensorYellow"
    : "sensorGreen";
}
