
import { defineComponent } from "vue";
import TrafficFeature from "@/typings/TrafficFeature";

export default defineComponent({
  props: {
    feature: {
      type: Object as () => TrafficFeature,
    },
  },
});
