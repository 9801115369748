
import { defineComponent } from "vue";

export default defineComponent({
  data: () => ({
    parkingList: null as HTMLFormElement | null,
  }),
  props: {
    data: Object,
  },
  mounted() {
    this.parkingList = this.$refs["parkingList"] as HTMLFormElement;

    if (window.matchMedia("(min-width: 801px)").matches) {
      this.parkingList.addEventListener("scroll", this.handleScrollDesktop);
    } else {
      window.addEventListener("scroll", this.handleScrollMobile);
    }
  },
  methods: {
    handleScrollMobile() {
      if (this.parkingList === null) {
        return;
      }
      if (
        this.data === undefined ||
        this.data === null ||
        this.data.current_page >= this.data.last_page
      ) {
        return;
      }

      if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
        this.$emit("loadNextPage", this.data.current_page + 1);
      }
    },
    handleScrollDesktop() {
      if (this.parkingList === null) {
        return;
      }
      if (
        this.data === undefined ||
        this.data === null ||
        this.data.current_page >= this.data.last_page
      ) {
        return;
      }
      if (
        this.parkingList.scrollTop !==
        this.parkingList.scrollHeight - this.parkingList.offsetHeight
      ) {
        return;
      }

      this.$emit("loadNextPage", this.data.current_page + 1);
    },
  },
  watch: {
    data(value) {
      if (this.parkingList === null) {
        return;
      }
      if (value.current_page === 1) {
        this.parkingList.scrollTo(0, 0);
      }
    },
  },
});
