import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sidebarButton" }
const _hoisted_2 = { class: "list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Accordion = _resolveComponent("Accordion")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: "showSidebar sidebar",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('sidebar-toggled')))
      })
    ]),
    _createVNode(_component_Accordion, {
      activeIndex: 0,
      class: "news"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.generateNewsFilters(), (archive) => {
          return (_openBlock(), _createBlock(_component_AccordionTab, {
            key: archive.year,
            header: archive.year
          }, {
            default: _withCtx(() => [
              _createElementVNode("ul", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(archive.items, (item) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: item.index
                  }, [
                    _createVNode(_component_router_link, {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('sidebar-toggled'))),
                      to: {
              name: 'News',
              query: { year: archive.year, month: item.index },
            }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]))
                }), 128))
              ])
            ]),
            _: 2
          }, 1032, ["header"]))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}