
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
import HCMore from "highcharts/highcharts-more";
import HCSolidGauge from "highcharts/modules/solid-gauge";

HCMore(Highcharts);
HCSolidGauge(Highcharts);

import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "BasicDonutChart",
  props: {
    percentage: { type: Number, required: true },
    stops: { type: Array as PropType<[number, string][]>, default: null },
    unit: { type: String, default: null },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
  },
  components: {
    highcharts: Chart,
  },
  computed: {
    chartOptions() {
      return {
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        chart: {
          plotBackgroundColor: "",
          plotBorderWidth: 0,
          plotShadow: false,
          type: "solidgauge",
        },
        pane: {
          size: "160%",
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: "#E0E0E0",
            innerRadius: "50%",
            outerRadius: "62%",
            shape: "arc",
            borderWidth: "0",
          },
        },
        yAxis: {
          min: this.min,
          max: this.max,
          lineWidth: 0,
          tickPositions: [],
          stops: this.stops,
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              enabled: true,
              borderWidth: 0,
              format: `{y} <span class='unit'>${this.unit}</span>`,
              align: "center",
              verticalAlign: "top",
              y: -45,
              style: {
                fontSize: "28px",
                color: "#1b1b1b",
              },
            },
          },
        },
        series: [
          {
            data: [
              {
                radius: "62%",
                innerRadius: "50%",
                y: this.percentage,
              },
            ],
          },
        ],
      };
    },
  },
});
