<template>
  <AirQuality />
  <!-- <EventCardMessage :is-loggedin="true" /> -->
</template>

<script>
import AirQuality from "@/components/home/AirQuality";
// import EventCardMessage from "@/components/events/EventCard";
export default {
  components: {
    // EventCardMessage,
    AirQuality,
  },
};
</script>

<style scoped></style>
