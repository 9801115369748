<template>
  <div class="wp1200 withPadding">
    <h2 class="mainTitle pages">Splošni pogoji uporabe</h2>

    <div>
      <p>
        To so
        <strong
          >splošni pogoji uporabe MOBILNE APLIKACIJE Logatec Supermesto</strong
        >
        (Smartcity Občina Logatec), ki določajo način in pogoje uporabe za njene
        <strong>registrirane uporabnike</strong>.
      </p>
      <p>
        Mobilna aplikacija Logatec Supermesto občanom občine Logatec in drugim
        obiskovalcem Logatca nudi brezplačne informacije in storitve, ki so
        povezane z mobilnostjo (npr. informacije o možnosti parkiranja),
        okoljem, vremenom, občinskimi dogodki itd. Uporabniki imajo možnost, da
        občini posredujejo tudi različne pobude, pohvale in pritožbe (vključno s
        posredovanjem fotografije). Če je s strani posameznika podano ustrezno
        soglasje za uporabo geolokacijskih podatkov, je možno pridobiti tudi
        lokacijsko prilagojene informacije (prometne, dogodki ipd.).
      </p>
      <p>
        Pred uporabo APLIKACIJE skrbno preberite te pogoje poslovanja. Z uporabo
        aplikacije se strinjate, da vas zavezujejo naslednji pogoji poslovanja
        (Pogoji). Če teh pogojev ne sprejemate, ne uporabljajte mobilne
        aplikacije.
      </p>
      <p>
        Sestavni del splošnih pogoje je
        <a href="#" @click="$router.push({ name: 'PrivacyPolicy' })"
          >Politika o zasebnosti</a
        >, ki zagotavlja informiranje posameznika o obdelavi osebnih podatkov, v
        skladu z določili 13. člena Uredbe Evropskega parlamenta in Sveta o
        varstvu posameznikov pri obdelavi osebnih podatkov in o prostem pretoku
        takih podatkov ter o razveljavitvi Direktive 95/46/ES (GDPR).
      </p>
    </div>

    <div>
      <h2>1. Kdo smo in kako nas kontaktirate</h2>
      <p>1.1. Občina Logatec, Tržaška cesta 50 A (p.p. 60). 1370 Logatec.</p>
      <p>
        1.2. Če nas želite kontaktirati, uporabite katerokoli od naslednjih
        podrobnosti: (A) <strong>Tel.:</strong>
        <a href="tel:(01)7590600">(01)7590600</a><strong> Fax.:</strong>
        <a href="tel:(01)7590620">(01)7590620</a>. <strong>Email:</strong>
        <a href="mailto:obcina.logatec@logatec.si">obcina.logatec@logatec.si</a
        >.
      </p>
      <p>
        1.3. Pogodbeni izvajalec oziroma skrbnik aplikacije je podjetje T-2
        d.o.o. Občina Logatec ima s skrbnikom sklenjeno pogodbo o izvajanju
        storitev razvijanja in vzdrževanja aplikacije in pogodbo o obdelavi
        osebnih podatkov, v skladu z določili 28. člena Splošne uredbe o varstvu
        osebnih podatkov (GDPR). Več o obdelavi in zagotavljanju varstva osebnih
        podatkov si preberite v
        <a href="./politika-zasebnosti.html" target="_blank"
          >Politiki zasebnosti</a
        >.
      </p>
    </div>

    <div>
      <h2>
        2. Občina Logatec vam na podlagi teh pogojev zagotavlja uporabo
        aplikacije
      </h2>
      <p>
        2.1. Te pogoje lahko Občina Logatec od časa do časa posodobi z objavo
        posodobljenih pogojev v aplikaciji, brez predhodnega obvestila. Kadar
        dostopate do aplikacije kadarkoli v prihodnosti, veljajo takrat veljavni
        takšni posodobljeni pogoji. Pogoje za posodobitve morate preveriti
        vsakič, ko dostopate do spletnega mesta.
      </p>
      <p>
        2.2 Občina Logatec lahko od časa do časa posodobi aplikacijo, da odraža
        potrebe uporabnikov in e prednostne naloge občine.
      </p>
      <p>
        2.3 Aplikacija vam je na voljo brezplačno. Ne jamčimo, da bo aplikacija
        vedno delovala nemoteno oz. neprekinjeno. Zaradi poslovnih ali
        operativnih razlogov lahko začasno prekinemo ali omejimo razpoložljivost
        vseh ali katerega koli dela aplikacije. Občina Logatec si pridružuje
        pravico, da kadarkoli spremeni oz. nadgradi aplikacijo
      </p>
      <p>
        2.4 Občina Logatec si pridržuje pravico, da kadarkoli zavrne ali
        prekliče registracijo v aplikaciji. Prav tako ste uporabniki odgovorni
        za zagotavljanje, da so vse osebe, ki dostopajo do aplikacije preko vaše
        mobilne naprave, seznanjene s temi pogoji in da delujejo v skladu z
        njimi.
      </p>
      <p>
        2.5 Če v okviru varnostnih postopkov izberete uporabniško
        identifikacijsko kodo, geslo ali kateri koli drug podatek, morate takšne
        podatke obravnavati kot zaupne. Tega ne smete razkriti nobeni tretji
        osebi.
      </p>
      <p>
        2.6 Čeprav se razumno trudimo posodobiti informacije, ne jamčimo za
        točnost in popolnost vseh informacij.
      </p>
      <p>
        2.7 Nismo odgovorni za informacije kjer naša aplikacije vsebuje povezave
        do drugih spletnih mest in virov, ki jih zagotavljajo tretje osebe.
        Takšnih povezav ne smemo razlagati kot odobritev teh povezanih spletnih
        strani ali informacij, ki jih lahko pridobite od njih, in vaša
        odgovornost je, da pregledate vse pogoje za vse povezane spletne strani.
      </p>
      <p>
        2.8 Ta aplikacija lahko vključuje informacije in dokumente, ki jih
        dobavljajo tretje osebe, vključno z našimi javnimi podjetji in drugimi
        partnerji. Te informacije in ti materiali niso bili preverjeni za
        točnost ali popolnost, niti jih ni potrdila ali odobrila Občina Logatec.
      </p>
    </div>

    <div>
      <h2>3. Razpoložljivost storitev</h2>
      <p>
        3.1. Občina Logatec zagotavlja delovanje aplikacije neprekinjeno, razen
        v primerih višje sile.
      </p>
      <p>
        3.2. Zaradi tehničnih razlogov (za potrebe vzdrževanja, nadgradnje ipd.)
        so možne krajše motnje dostopa ali prekinitve delovanja.
      </p>
      <p>
        3.3. Uporabnik v okviru aplikacije dostopa tudi do podatkov naših
        partnerjev in drugih tretjih oseb. V tem primeru je lahko moteno tudi
        delovanje aplikacije.
      </p>
    </div>

    <div>
      <h2>4. Uporaba</h2>
      <p>
        4.1 Vse gradivo na spletni strani je last Občine Logatec ali njenih
        partnerjev in je zaščiteno z avtorskimi pravicami in drugimi pravicami
        intelektualne lastnine.
      </p>
      <p>
        4.2. Pooblaščeni ste, da si ogledate in prenesete gradiva samo za vašo
        osebno, nekomercialno uporabo.
      </p>
      <p>
        4.3 Občina Logatec si pridržuje pravico, da vam kadarkoli omeji dostop
        ali prepreči uporabo aplikacije, če obstaja sum zlorabe ali, da
        uporabnikovo ravnanje ogroža delovanje aplikacije.
      </p>
      <p>
        4.4. Vaše osebne podatke bomo uporabili le v skladu s sprejeto Politiko
        zasebnosti.
      </p>
      <p>
        4.5. Aplikacijo lahko uporabljajo osebe stare 15 ali več let. Predvidena
        je potrditev starosti v registracijskem postopku za uporabo aplikacije
        Logatec Supermesto.
      </p>
    </div>

    <div>
      <h2>5. Odgovornost</h2>
      <p>5.1.Uporabnik uporablja aplikacijo na lastno odgovornost.</p>
      <p>
        5.2. Občina Logatec ne odgovarja za škodo, ki bi lahko nastala
        uporabniku zaradi napačnega dostopa, napačne uporabe ali nezmožnosti
        uporabe podatkov in aplikacije kot take.
      </p>
      <p>
        5.3. Uporabniki morajo sami zagotoviti ukrepe za varovanje svoje
        informacijske opreme, ki so potrebne za nemoten in varen dostop do
        podatkov (npr. preprečevanje nepooblaščenih dostopov, zaščita pred
        vdori, zaščita pred virusi in drugo zlonamerno opremo…).
      </p>
      <p>
        5.4 Občina Logatec ne prevzema nobene odgovornosti, ki bi izhajala iz
        izvajanja storitev naših partnerjev.
      </p>
    </div>

    <div>
      <h2>6. Končne določbe</h2>
      <p>
        6.1. Z naložitvijo aplikacije, vstopom, prijavo oz. registracijo
        uporabnik potrjuje, da je prebral te splošne pogoje, se z njimi strinja
        in jih sprejema v celoti.
      </p>
      <p>
        6.2. V primeru kršitev teh splošnih pogojev uporabnik odgovarja za
        neupravičeno pridobitev podatkov in vso škodo, ki bi pri tem nastala.
      </p>
      <p>
        6.3. Morebitni spori zaradi kršitev teh splošnih pogojev, se rešujejo po
        mirni poti. Kadar to ni mogoče, je za reševanje sporov pristojno sodišče
        na Vrhniki.
      </p>
      <p>
        6.4. Občina Logatec lahko spremeni te pogoje skladno s poslovno politiko
        in veljavno zakonodajo. Spremembe pričnejo veljati s pričetkom
        naslednjega dne, ki sledi dnevu objave spremenjenih splošnih pogojev. Za
        informacije o tem, kdaj so bili splošni pogoji nazadnje spremenjeni,
        prosimo, preverite datum objave na vrhu dokumenta.
      </p>
      <p>6.5. Ti splošni pogoji začnejo veljati z dnem objave.</p>
      <address>Občina Logatec</address>
      <address>Župan: Berto Menard</address>
    </div>
    <br />
  </div>
</template>

<script>
export default {
  name: "TermsAndConditions",
};
</script>

<style scoped></style>
