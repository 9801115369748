
import { defineComponent } from "vue";
import CustomButton from "@/components/customComponents/CustomButton.vue";

export default defineComponent({
  data: () => ({
    open: false,
    consents: {
      system: true,
      analytics: false,
      social: false,
    },
  }),
  created() {
    if (this.$store.getters["consents/consents"] === null) {
      this.open = true;
      return;
    }
    this.consents = this.$store.getters["consents/consents"];
  },
  methods: {
    selectAllAndClose() {
      this.consents.system = true;
      this.consents.analytics = true;
      this.consents.social = true;
      this.save();
    },
    save() {
      this.$store.dispatch("consents/setConsents", this.consents);
      this.open = false;
    },
  },
  components: { CustomButton },
});
