import axios from "axios";
import VueAxios from "vue-axios";
import Qs from "qs";

axios.defaults.baseURL = window.VUE_APP_API_URL || process.env.VUE_APP_API_URL;

axios.interceptors.request.use(
  (config) => {
    config.paramsSerializer = (params) => {
      return Qs.stringify(params, {
        arrayFormat: "brackets",
        encode: false,
      });
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { axios, VueAxios };
