
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
import HCMore from "highcharts/highcharts-more";
import HCSolidGauge from "highcharts/modules/solid-gauge";

HCMore(Highcharts);
HCSolidGauge(Highcharts);

import { ref, PropType, defineComponent } from "vue";

export default defineComponent({
  name: "AdvancedGaugeChartHome",
  props: {
    percentage: { type: Number, required: true },
    bands: {
      type: Array as PropType<
        {
          from: number;
          to: number;
          color: string;
          thickness: string;
        }[]
      >,
      default: null,
    },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
  },
  components: {
    highcharts: Chart,
  },
  setup(props) {
    const chartOptions = ref({
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        type: "gauge",
      },
      pane: {
        center: ["50%", "30%"],
        size: "90%",
        startAngle: -130,
        endAngle: 130,
        background: [],
      },
      yAxis: {
        min: props.min,
        max: props.max,
        lineWidth: 0,
        tickPositions: [],
        plotBands: props.bands,
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        gauge: {
          dataLabels: {
            enabled: true,
            borderWidth: 0,
            align: "center",
            verticalAlign: "center",
            y: 30,
            style: {
              fontSize: "21px",
            },
          },
          dial: {
            radius: "45%",
            backgroundColor: "#C9BCA9",
            baseWidth: 23,
            topWidth: 1,
            baseLength: "5%", // of radius
            rearLength: "0%",
          },
          pivot: {
            radius: 12,
            backgroundColor: "#C9BCA9",
          },
        },
      },
      series: [
        {
          data: [
            {
              radius: "72%",
              innerRadius: "65%",
              y: props.percentage,
            },
          ],
        },
      ],
    });

    return {
      chartOptions,
    };
  },
});
