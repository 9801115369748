
import { defineComponent } from "vue";
import SidebarWrapper from "@/components/SidebarWrapper.vue";
import TrafficInfoCard from "@/components/trafficInfo/TraficInfoCard.vue";
import SidebarTrafficInfo from "@/components/trafficInfo/SidebarTrafficInfo.vue";
import { QueryParameters } from "@/typings/QueryParameters";
import TrafficFeature from "@/typings/TrafficFeature";

export default defineComponent({
  data: () => ({
    sidebarComponent: SidebarTrafficInfo,
    traffic: {
      features: [] as TrafficFeature[],
    },
  }),
  components: { TrafficInfoCard, SidebarWrapper },
  mounted() {
    this.loadTraffic();
  },
  methods: {
    loadTraffic() {
      let params: QueryParameters = {};
      this.$http.get("api/v1/traffic", { params }).then((response) => {
        this.traffic = response.data;
      });
    },
  },
});
