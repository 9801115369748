
import { defineComponent } from "vue";
import CalendarIcon from "@/components/customComponents/CalendarIcon.vue";
import { Event } from "@/typings/Event";

export default defineComponent({
  data: () => ({
    event: null as Event | null,
  }),
  mounted() {
    this.loadEvents();
  },
  methods: {
    loadEvents() {
      this.$http
        .get("api/v1/events/" + this.$route.params.id)
        .then((response) => {
          this.event = response.data;
        });
    },
  },
  components: {
    CalendarIcon,
  },
});
