import { createApp } from "vue";
import App from "@/App.vue";
import PrimeVue from "@/plugins/primevue";
import "primeicons/primeicons.css";
import router from "@/plugins/router";
import store from "@/plugins/store";
import { axios, VueAxios } from "@/plugins/axios";
import moment from "@/plugins/moment";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import i18n from "@/plugins/i18n";

import "@/assets/scss/main.scss";

const app = createApp(App)
  .use(VueGoogleMaps, {
    load: {
      key:
        window.VUE_APP_GOOGLE_MAPS_KEY || process.env.VUE_APP_GOOGLE_MAPS_KEY,
      libraries: "places",
    },
  })
  .use(PrimeVue)
  .use(router)
  .use(store)
  .use(i18n)
  .use(VueAxios, axios);

app.config.globalProperties.$moment = moment;

app.mount("#app");

declare global {
  interface Window {
    VUE_APP_API_URL: string;
    VUE_APP_CITY_ID: string;
    VUE_APP_GOOGLE_MAPS_KEY: string;
  }
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    // eslint-disable-next-line
    $moment: any;
    // eslint-disable-next-line
    $store: any;
  }
}
