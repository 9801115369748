
import CalendarIcon from "@/components/customComponents/CalendarIcon.vue";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    event: Object,
  },
  components: { CalendarIcon },
});
