<template>
  <div class="wp1200 withPadding survey">
    <div class="mainTitle">
      <h2 class="surveyMainTitle">Ankete</h2>
      <hr />
    </div>
    <div class="homeTabs">
      <div class="flex">
        <router-link to="/survey/list/active">Aktivne</router-link>
        <router-link to="/survey/list/completed">Zaključene</router-link>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "SurveyList",
};
</script>

<style scoped></style>
