import { RouteRecordRaw } from "vue-router";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import Wrapper from "@/layout/Wrapper.vue";

import Home from "@/views/home/Home.vue";
import UserTabs from "@/views/home/UserTabs.vue";
import Feed from "@/views/home/Feed.vue";
import MyCity from "@/views/home/MyCity.vue";

import Events from "@/views/events/Events.vue";
import EventView from "@/views/events/EventView.vue";

import News from "@/views/news/News.vue";
import NewsView from "@/views/news/NewsView.vue";

import Weather from "@/views/weather/Weather.vue";
import AirQuality from "@/views/airQuality/AirQuality.vue";
import Parking from "@/views/parking/Parking.vue";
import TrafficInfo from "@/views/trafficInfo/TrafficInfo.vue";

import PublicTransport from "@/views/publicTransport/PublicTransport.vue";
import Lines from "@/views/publicTransport/Lines.vue";
import Stations from "@/views/publicTransport/Stations.vue";
import FindPath from "@/views/publicTransport/FindPath.vue";

import AboutUs from "@/views/pages/AboutUs.vue";
import PrivacyPolicy from "@/views/pages/PrivacyPolicy.vue";
import TermsAndConditions from "@/views/pages/TermsConditions.vue";

import SurveyView from "@/views/survey/SurveyView.vue";
import SurveyList from "@/views/survey/SurveyList.vue";
import Active from "@/views/survey/Active.vue";
import Completed from "@/views/survey/Completed.vue";

import { Component } from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "home",
        component: UserTabs,
        children: [
          {
            path: "feed",
            name: "Feed",
            component: Feed,
          },
          {
            path: "my-city",
            name: "MyCity",
            component: MyCity,
          },
          {
            path: "",
            redirect: { name: "Feed" },
          },
        ],
      },
      {
        path: "events",
        component: Wrapper,
        children: [
          {
            path: "",
            name: "Events",
            component: Events,
          },
          {
            path: ":id",
            name: "Event",
            component: EventView,
          },
        ],
      },
      {
        path: "news",
        component: Wrapper,
        children: [
          {
            path: "",
            name: "News",
            component: News,
          },
          {
            path: ":id",
            name: "Article",
            component: NewsView,
          },
        ],
      },
      {
        path: "/weather",
        name: "Weather",
        component: Weather,
      },
      {
        path: "/air-quality",
        component: Wrapper,
        children: [
          {
            path: ":id",
            name: "AirQualityById",
            component: AirQuality,
          },
          {
            path: "",
            name: "AirQuality",
            component: AirQuality,
          },
        ],
      },
      {
        path: "/parking",
        component: Wrapper,
        children: [
          {
            path: ":id",
            name: "ParkingById",
            component: Parking,
          },
          {
            path: "",
            name: "Parking",
            component: Parking,
          },
        ],
      },
      {
        path: "/traffic-info",
        name: "TrafficInfo",
        component: TrafficInfo,
      },
      {
        path: "/public-transport",
        name: "PublicTransport",
        component: PublicTransport,

        children: [
          {
            path: "find-path",
            name: "FindPath",
            component: FindPath,
          },
          {
            path: "stations",
            component: Wrapper,
            children: [
              {
                path: ":id",
                name: "StationById",
                component: Stations,
              },
              {
                path: "",
                name: "Station",
                component: Stations,
              },
            ],
          },
          {
            path: "lines",
            component: Wrapper,
            children: [
              {
                path: ":id",
                name: "LinesById",
                component: Lines,
              },
              {
                path: "",
                name: "Lines",
                component: Lines,
              },
            ],
          },
        ],
      },
      {
        path: "/about-us",
        name: "AboutUs",
        component: AboutUs,
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
      },
      {
        path: "/terms-and-conditions",
        name: "TermsAndConditions",
        component: TermsAndConditions,
      },

      {
        path: "/survey",
        name: "Survey",
        component: Wrapper,
        children: [
          {
            path: "",
            component: SurveyView,
          },
          {
            path: "list",
            name: "List",
            component: SurveyList,
            children: [
              {
                path: "active",
                name: "Active",
                component: Active,
              },
              {
                path: "completed",
                name: "Completed",
                component: Completed,
              },
            ],
          },
        ],
      },
    ],
  },
];

declare module "vue-router" {
  interface RouteMeta {
    // is optional
    title?: string;
    sidebar?: Component;
    isLeftSidebar?: boolean;
  }
}

export default routes;
