
import { defineComponent } from "vue";
import NewsCard from "@/components/news/NewsCard.vue";
import SidebarWrapper from "@/components/SidebarWrapper.vue";
import SidebarNews from "@/components/news/SidebarNews.vue";
import { QueryParameters } from "@/typings/QueryParameters";

export default defineComponent({
  data: () => ({
    sidebarComponent: SidebarNews,
    news: {
      data: [],
    },
  }),
  mounted() {
    this.loadNews();
  },
  methods: {
    loadNews() {
      let params: QueryParameters & { created_at?: [string, string] } = {
        orderBy: { datetime: "DESC" },
      };
      if (
        this.selectedYearAndMonth.year !== null &&
        this.selectedYearAndMonth.month !== null
      ) {
        params.created_at = [
          this.$moment([
            this.selectedYearAndMonth.year,
            this.selectedYearAndMonth.month - 1,
          ]).format("YYYY-MM-DD"),
          this.$moment([
            this.selectedYearAndMonth.year,
            this.selectedYearAndMonth.month - 1,
          ])
            .endOf("month")
            .format("YYYY-MM-DD"),
        ];
      }
      this.$http.get("api/v1/news", { params }).then((response) => {
        this.news = response.data;
      });
    },
  },
  computed: {
    selectedYearAndMonth() {
      let year =
        Object.prototype.hasOwnProperty.call(this.$route.query, "year") &&
        this.$route.query.year !== null
          ? parseInt(this.$route.query.year.toString())
          : null;

      let month =
        Object.prototype.hasOwnProperty.call(this.$route.query, "month") &&
        this.$route.query.month !== null
          ? parseInt(this.$route.query.month.toString())
          : null;
      return { year, month };
    },
  },
  watch: {
    selectedYearAndMonth() {
      this.loadNews();
    },
  },
  components: {
    SidebarWrapper,
    NewsCard,
  },
});
