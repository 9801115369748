import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "trafficInfo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TrafficInfoCard = _resolveComponent("TrafficInfoCard")!
  const _component_SidebarWrapper = _resolveComponent("SidebarWrapper")!

  return (_openBlock(), _createBlock(_component_SidebarWrapper, {
    title: _ctx.$t('traffic_info.title'),
    sidebarComponent: _ctx.sidebarComponent
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.traffic.features, (feature) => {
          return (_openBlock(), _createBlock(_component_TrafficInfoCard, {
            feature: feature,
            key: feature
          }, null, 8, ["feature"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title", "sidebarComponent"]))
}