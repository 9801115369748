import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex cookies", { active: _ctx.open }])
  }, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.$t("cookies.policy")), 1),
    _createElementVNode("p", null, [
      _createTextVNode(_toDisplayString(_ctx.$t("cookies.uses_cookies")) + " ", 1),
      _createVNode(_component_router_link, { to: { name: 'PrivacyPolicy' } }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("cookies.here")), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("ul", null, [
      _createElementVNode("li", null, [
        _createElementVNode("label", null, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.consents.system) = $event)),
            disabled: ""
          }, null, 512), [
            [_vModelCheckbox, _ctx.consents.system]
          ]),
          _createTextVNode(_toDisplayString(_ctx.$t("cookies.system")), 1)
        ])
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("label", null, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.consents.analytics) = $event))
          }, null, 512), [
            [_vModelCheckbox, _ctx.consents.analytics]
          ]),
          _createTextVNode(_toDisplayString(_ctx.$t("cookies.analize")), 1)
        ])
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("label", null, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.consents.social) = $event))
          }, null, 512), [
            [_vModelCheckbox, _ctx.consents.social]
          ]),
          _createTextVNode(_toDisplayString(_ctx.$t("cookies.social")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CustomButton, {
        label: _ctx.$t('cookies.all'),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectAllAndClose()))
      }, null, 8, ["label"]),
      _createVNode(_component_CustomButton, {
        class: "choose",
        label: _ctx.$t('cookies.mandatory'),
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.save()))
      }, null, 8, ["label"])
    ])
  ], 2))
}