
import { defineComponent } from "vue";

export default defineComponent({
  data: () => ({
    article: null,
  }),
  mounted() {
    this.loadArticle();
  },
  methods: {
    loadArticle() {
      this.$http
        .get("api/v1/news/" + this.$route.params.id)
        .then((response) => {
          this.article = response.data;
        });
    },
  },
});
