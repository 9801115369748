
import { defineComponent } from "vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

export default defineComponent({
  methods: {
    generateNewsFilters() {
      let filters = [];
      let currentYear = this.$moment().year();
      let currentMonth = this.$moment().month();

      filters.push({
        year: currentYear,
        items: this.months.slice(12 - currentMonth),
      });
      for (let year = currentYear - 1; year > currentYear - 4; year--) {
        let items = this.months;
        filters.push({
          year,
          items,
        });
      }
      return filters;
    },
  },
  computed: {
    months(): Array<{ index: number; name: string }> {
      return this.$moment
        .months()
        .map((month: string, index: number) => ({
          index: index + 1,
          name: month.charAt(0).toUpperCase() + month.slice(1),
        }))
        .reverse();
    },
  },
  components: { Accordion, AccordionTab },
});
