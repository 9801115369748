import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wp1200 withPadding" }
const _hoisted_2 = { class: "mainTitle" }
const _hoisted_3 = { class: "col2_3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.mapComponent)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.mapComponent), { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        class: _normalizeClass(["showSidebar", { left: _ctx.sidebarLeft }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCard && _ctx.toggleCard(...args)))
      }, null, 2),
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", {
        class: _normalizeClass(["flex", { left: _ctx.sidebarLeft }])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["col1_3 sideBar right", { closed: _ctx.cardOpen }])
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.sidebarComponent), { onSidebarToggled: _ctx.toggleSidebar }, null, 8, ["onSidebarToggled"]))
        ], 2)
      ], 2)
    ])
  ], 64))
}