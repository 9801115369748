import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "transport" }
const _hoisted_2 = { class: "searchStations" }
const _hoisted_3 = { class: "wrapper" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "from" }
const _hoisted_6 = {
  key: 0,
  class: "flex dist"
}
const _hoisted_7 = { class: "stationName flex wide" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_InfinityScroll = _resolveComponent("InfinityScroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Search, {
        modelValue: _ctx.searchTerm,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadStops()))
      }, null, 8, ["modelValue"])
    ]),
    _createVNode(_component_InfinityScroll, {
      onLoadNextPage: _ctx.loadStops,
      data: _ctx.stops,
      class: "stations"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stops.data, (stop) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            to: { name: 'StationById', params: { id: stop.id } },
            class: "flex customTabLink",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('sidebar-toggled'))),
            key: stop.id
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, [
                  _createTextVNode(_toDisplayString(_ctx.$t("sidebar_stations.station")) + " ", 1),
                  _createElementVNode("strong", _hoisted_5, _toDisplayString(stop.name), 1)
                ]),
                (stop.route_names)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stop.route_names.split(','), (route) => {
                          return (_openBlock(), _createElementBlock("span", {
                            key: route,
                            class: "stationNumber"
                          }, _toDisplayString(route), 1))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["onLoadNextPage", "data"])
  ]))
}